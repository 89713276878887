import React, { useEffect, useState } from "react";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress from "nprogress";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import {
	Autocomplete,
	Card,
	Divider,
	Flex,
	Grid,
	Group,
	Paper,
	Text,
	CloseIcon,
	Button,
	Menu,
	Badge,
} from "@mantine/core";
import { AreaChart } from "@mantine/charts";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import Swal from "sweetalert2";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ToggleFiltersButton,
} from "mantine-react-table";
import { Link } from "react-router-dom";

let formattedNumber = (number) =>
	new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(number);

export default function ProductAnalyticsList() {
	const { lang } = useLang();
	const [products, setProducts] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [weight, setWeight] = useState(0);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedProductHistory, setSelectedProductHistory] = useState([]);

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [howManyKGSelledProduct, setHowManyTimesSelledProduct] = useState(0);
	const [howManyKGEntryProduct, setHowManyKGEntryProduct] = useState(0);
	const [howManyKGRemainProduct, setHowManyKGRemainProduct] = useState(0);

	const [productAnalytics, setProductAnalytics] = useState([
		{
			product: "",
			entry: 0,
			selled: 0,
			remains: 0,
			date: "",
			difference: 0,
		},
	]);
	const [totalProductAnalytics, setTotalProductAnalytics] = useState([]);
	const [totalProductsPrice, setTotalProductsPrice] = useState(0);

	const columns = [
		{
			accessorKey: "ID",
			permissionModel: "ID",
			header: "ID",
			size: 100,
			enableClickToCopy: true,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = row.product ? row.product : 0;
				return <Link to={`product/${row._id}`}>{result}</Link>;
			},
		},
		{
			accessorKey: "price",
			permissionModel: "price",
			header: config.translate.price2[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				if (row.price) {
					return <Text fw={"bold"}>{formattedNumber(row.price)}</Text>;
				}
				return null;
			},
		},
		{
			accessorKey: "totalPrice",
			permissionModel: "price",
			header: config.translate.price[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				if (row.totalPrice) {
					return (
						<Text fw={"bold"} c={"red"}>
							{formattedNumber(row.totalPrice)}
						</Text>
					);
				}
				return null;
			},
		},
		{
			accessorKey: "weight",
			permissionModel: "weight",
			header: config.translate.weight[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return formattedNumber(row.weight);
			},
		},
		{
			accessorKey: "quantity",
			permissionModel: "quantity",
			header: config.translate.quantity[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return formattedNumber(row.quantity);
			},
		},
		{
			accessorKey: "diameter",
			permissionModel: "diameter",
			header: config.translate.diameter[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.diameter;
			},
		},
		{
			accessorKey: "difference",
			permissionModel: "diameter",
			header: config.translate.difference[lang],
			enableFilter: false,
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				if (row.difference === 0 || !row.difference) return null;
				return (
					<Badge color={row.difference < 0 ? "red.6" : "green.6"} size="lg">
						{formattedNumber(row.difference)}
					</Badge>
				);
			},
		},
		{
			accessorKey: "taxCode",
			permissionModel: "taxCode",
			header: config.translate.taxCode[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.taxCode ? row.taxCode : "";
			},
		},
	];

	const columns2 = [
		{
			accessorKey: "ID",
			permissionModel: "ID",
			header: "ID",
			size: 100,
			enableClickToCopy: true,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = row.product ? row.product : 0;
				return <Link to={`product/${row._id}`}>{result}</Link>;
			},
		},
		{
			accessorKey: "price",
			permissionModel: "price",
			header: config.translate.price[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				if (row.totalPrice) {
					return (
						<Text fw={"bold"} c={"red"}>
							{formattedNumber(row.totalPrice)}
						</Text>
					);
				}
				return null;
			},
		},
		{
			accessorKey: "weight",
			permissionModel: "weight",
			header: config.translate.weight[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return formattedNumber(row.weight);
			},
		},
		{
			accessorKey: "quantity",
			permissionModel: "quantity",
			header: config.translate.quantity[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return formattedNumber(row.quantity);
			},
		},
		{
			accessorKey: "diameter",
			permissionModel: "diameter",
			header: config.translate.diameter[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.diameter;
			},
		},
		{
			accessorKey: "difference",
			permissionModel: "price",
			header: config.translate.difference[lang],
			enableFilter: false,
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				if (row.difference === 0 || !row.difference) return null;
				return (
					<Badge color={row.difference < 0 ? "red.6" : "green.6"} size="lg">
						{formattedNumber(row.difference)}
					</Badge>
				);
			},
		},
		{
			accessorKey: "taxCode",
			permissionModel: "taxCode",
			header: config.translate.taxCode[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.taxCode ? row.taxCode : "";
			},
		},
	];

	const columns3 = [
		{
			accessorKey: "ID",
			permissionModel: "ID",
			header: config.translate.type[lang],
			size: 140,
			enableClickToCopy: true,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.type === "entry" ? (
					<Badge color="green" size="lg" variant="filled">
						<Link
							to={`/product-entry/${row._id}`}
							style={{ color: "white", textDecoration: "none" }}
						>
							{config.translate.entry[lang]}
						</Link>
					</Badge>
				) : (
					<Badge color="red" size="lg" variant="filled">
						<Link
							to={`/order/${row._id}`}
							style={{ color: "white", textDecoration: "none" }}
						>
							{config.translate.order[lang]}
						</Link>
					</Badge>
				);
			},
		},
		{
			accessorKey: "date",
			permissionModel: "ID",
			header: config.translate.date[lang],
			size: 150,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return moment(row.date).format("DD/MM/YYYY");
			},
		},
		{
			accessorKey: "price",
			permissionModel: "price",
			header: config.translate.price2[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				if (row.price) {
					return (
						<Text fw={"bold"} c={"red"}>
							{formattedNumber(row.price)}
						</Text>
					);
				}
				return null;
			},
		},
		{
			accessorKey: "totalPrice",
			permissionModel: "price",
			header: config.translate.price[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				if (row.totalPrice) {
					return (
						<Text fw={"bold"} c={"red"}>
							{formattedNumber(row.totalPrice)}
						</Text>
					);
				}
				return null;
			},
		},
		{
			accessorKey: "difference",
			permissionModel: "price",
			header: config.translate.difference[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result =
					row.difference > 0 ? (
						<Badge color="green.6" size="lg">
							{formattedNumber(row.difference)}
						</Badge>
					) : null;
				return result;
			},
		},

		{
			accessorKey: "weight",
			permissionModel: "weight",
			header: config.translate.weight[lang],
			filterVariant: "range",
			filterFn: "between",
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				if (row.weight) {
					return formattedNumber(row.weight);
				}
				return null;
			},
		},
		{
			accessorKey: "quantity",
			permissionModel: "quantity",
			header: config.translate.quantity[lang],
			filterVariant: "range",
			filterFn: "between",
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				if (row.quantity) {
					return formattedNumber(row.quantity);
				}
				return null;
			},
		},
		{
			accessorKey: "diameter",
			permissionModel: "diameter",
			header: config.translate.diameter[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				if (row.diameter) {
					return row.diameter;
				}
				return null;
			},
		},
	];

	const table = useMantineReactTable({
		columns: columns ? columns : [],
		layoutMode: "grid-no-grow",
		columnResizeMode: "onChange",
		data: productAnalytics ? productAnalytics : [],
		columnVirtualizerProps: {
			overscan: 5, //adjust the number of columns that are rendered to the left and right of the visible area of the table
			estimateSize: () => 40, //if your columns are wider or , try tweaking this value to make scrollbar size more accurate
		},
		rowVirtualizerProps: {
			overscan: 10, //adjust the number or rows that are rendered above and below the visible area of the table
			estimateSize: () => 10, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
		},
		manualPagination: true,
		manualFiltering: true,
		manualSorting: false,
	});

	const table2 = useMantineReactTable({
		columns: columns2 ? columns2 : [],
		layoutMode: "grid-no-grow",
		columnResizeMode: "onChange",
		data: totalProductAnalytics ? totalProductAnalytics : [],
		columnVirtualizerProps: {
			overscan: 5, //adjust the number of columns that are rendered to the left and right of the visible area of the table
			estimateSize: () => 40, //if your columns are wider or , try tweaking this value to make scrollbar size more accurate
		},
		rowVirtualizerProps: {
			overscan: 10, //adjust the number or rows that are rendered above and below the visible area of the table
			estimateSize: () => 10, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
		},
		manualPagination: true,
		manualFiltering: true,
		manualSorting: false,
	});

	const table3 = useMantineReactTable({
		columns: columns3 ? columns3 : [],
		layoutMode: "grid-no-grow",
		columnResizeMode: "onChange",
		data: selectedProductHistory ? selectedProductHistory : [],
		columnVirtualizerProps: {
			overscan: 5, //adjust the number of columns that are rendered to the left and right of the visible area of the table
			estimateSize: () => 40, //if your columns are wider or , try tweaking this value to make scrollbar size more accurate
		},
		rowVirtualizerProps: {
			overscan: 10, //adjust the number or rows that are rendered above and below the visible area of the table
			estimateSize: () => 10, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
		},
		manualPagination: false,
		manualFiltering: false,
		manualSorting: false,
	});

	function ChartTooltip({ label, payload }) {
		if (!payload) return null;
		return (
			<Paper px="md" py="sm" withBorder shadow="md" radius="md">
				<Text fw={500} mb={5}>
					{/* if label is date then moment it */}
					{moment(label).format("MMM D, YYYY")}
				</Text>
				{payload.map((item) => (
					<Text key={item.name} c={item.color} fz="sm">
						{config.translate?.[item.name]?.[lang]}: {item.value}
					</Text>
				))}
			</Paper>
		);
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawProducts = await API.get(config.api.product);
			if (rawProducts) setProducts(rawProducts);

			setWeight(weight);

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		// selectedProduct relaterd data of orders when this product is selected
		(async () => {
			let rawOrders = await API.get(
				config.api.order,
				{
					date: {
						$gte: startDate.toLocaleString().split(",")[0],
						$lte: endDate.toLocaleString().split(",")[0],
					},
				},
				null,
				null,
				null,
				["cart.product"]
			);
			let productAnalytics = [];
			let selectedProductHistory = [];

			if (selectedProduct) {
				const selectedProductId = selectedProduct._id;
				const productAnalyticsMap = new Map();

				// Step 1: Process orders to populate initial product analytics data
				rawOrders.forEach((order) => {
					const cartItems = order.cart.filter(
						(item) => item.product._id === selectedProductId
					);
					if (cartItems.length > 0) {
						cartItems.forEach((cartItem) => {
							selectedProductHistory.push({
								_id: order._id,
								product: cartItem.product._id,
								price: cartItem.price,
								totalPrice: cartItem.totalPrice,
								difference: cartItem.difference,
								date: order.date.toLocaleString().split(",")[0],
								weight: cartItem.weight,
								quantity: cartItem.quantity,
								diameter: cartItem.diameter,
								type: "order",
							});
							const orderDate = new Date(order.date).toLocaleDateString(); // Ensure date conversion
							if (!productAnalyticsMap.has(orderDate)) {
								productAnalyticsMap.set(orderDate, { entry: 0, selled: 0 });
							}
							productAnalyticsMap.get(orderDate).selled += cartItem.weight;
						});
					}
				});

				// Step 2: Process entries to add entry weights to the same dates in productAnalyticsMap
				const productEntries = await API.get(config.api.productEntry, {
					date: { $gte: startDate, $lte: endDate },
				});

				productEntries.forEach((entry) => {
					if (entry.product === selectedProductId) {
						const entryDate = new Date(entry.date).toLocaleDateString(); // Ensure date conversion
						if (!productAnalyticsMap.has(entryDate)) {
							productAnalyticsMap.set(entryDate, { entry: 0, selled: 0 });
						}
						productAnalyticsMap.get(entryDate).entry += entry.weight;

						selectedProductHistory.push({
							_id: entry._id,
							product: entry.product,
							price: entry.price,
							totalPrice: entry.price * entry.weight,
							difference: 0,
							date: entry.date.toLocaleString().split(",")[0],
							weight: entry.weight,
							diameter: entry.diameter,
							quantity: entry.quantity,
							type: "entry",
						});
					}
				});

				// Step 3: Convert Map to sorted array and compute cumulative remains
				let cumulativeRemain = selectedProduct.weight;
				let cumulativeEntry = 0;
				let cumulativeSelled = 0;

				const productAnalytics = Array.from(productAnalyticsMap.entries())
					.sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
					.map(([date, { entry, selled }]) => {
						cumulativeEntry += entry;
						cumulativeSelled += selled;
						cumulativeRemain = cumulativeEntry - cumulativeSelled;

						return {
							product: selectedProduct.ID,
							date,
							entry: formattedNumber(entry),
							selled: formattedNumber(selled),
							remains: formattedNumber(cumulativeRemain),
						};
					});

				// reorder the selectedProductHistory by date
				selectedProductHistory.sort((a, b) => new Date(b.date) - new Date(a.date));

				// Step 4: Set the computed values
				setHowManyTimesSelledProduct(cumulativeSelled);
				setHowManyKGRemainProduct(formattedNumber(cumulativeRemain));
				setHowManyKGEntryProduct(cumulativeEntry);
				setProductAnalytics(productAnalytics);
				setSelectedProductHistory(selectedProductHistory);
			} else {
				// if no product selected show all products with selected date range

				let productOrders = await API.get(
					config.api.order,
					{
						date: {
							$gte: startDate,
							$lte: endDate,
						},
					},
					null,
					null,
					null,
					["cart.product"]
				);

				productOrders.forEach((order) => {
					order.cart.forEach((cartItem) => {
						productAnalytics.push({
							...cartItem,
							taxCode: cartItem.product.taxCode,
							date: order.date.toLocaleString().split(",")[0],
							product: cartItem.product.ID,
							difference: cartItem.difference,
						});
					});
				});

				// find and combine all products by ID and create u nuique array of products with total selled weight
				let totalProductAnalytics = [];

				totalProductAnalytics = productAnalytics.reduce((acc, product) => {
					let existingProduct = acc.find((p) => p.product === product.product);
					if (existingProduct) {
						existingProduct.weight += product.weight;
						existingProduct.totalPrice += product.totalPrice;
						existingProduct.quantity += product.quantity;
						existingProduct.diameter = product.diameter;
						existingProduct.taxCode = product.taxCode;
						if (product.difference) existingProduct.difference += product.difference;
					} else {
						acc.push({
							product: product.product,
							weight: product.weight,
							totalPrice: product.totalPrice,
							quantity: product.quantity,
							diameter: product.diameter,
							taxCode: product.taxCode,
							difference: product.difference || 0,
						});
					}
					return acc;
				}, []);

				setTotalProductsPrice(
					totalProductAnalytics.reduce((acc, product) => acc + product.totalPrice, 0)
				);

				setTotalProductAnalytics(totalProductAnalytics);
				setProductAnalytics(productAnalytics);
			}
			NProgress.done();
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProduct, startDate, endDate]);

	if (!isBusy) {
		if (startDate && endDate) {
			if (startDate > endDate) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Start date can't be greater than end date",
				});
				setStartDate(endDate);
			}

			return (
				<>
					<Paper withBorder p="md" radius="md" shadow="xs">
						<Grid gutter="md" columns={12} justify="center" align="center">
							<Grid.Col span={3}>
								<Group justify="center">
									<Text size="xl" weight={700} w={"100%"} align="center">
										{config.translate.startDate[lang]}
									</Text>
									<DatePicker
										value={startDate}
										onChange={(date) => {
											//  dahealt date is GMT=4 change it to MGMT=0
											date.setHours(0);
											setStartDate(date);
										}}
										valueFormat="DD/MM/YYYY"
										firstDayOfWeek={6}
									/>
								</Group>
							</Grid.Col>
							<Grid.Col span={6}>
								<Text size="xl" weight={700} align="center" w={"100%"}>
									{config.translate.product[lang]}
								</Text>
								<Autocomplete
									label={config.translate.selectProduct[lang]}
									placeholder={config.translate.selectProduct[lang]}
									mb={40}
									data={products.flatMap((product) => {
										return {
											value: product._id,
											label: String(product.ID),
										};
									})}
									value={selectedProduct?.ID || ""}
									onSearchChange={(searchValue) => {
										// This handles input changes for searching
										setSelectedProduct({ ID: Number(searchValue) });
									}}
									onChange={(productLabel) => {
										// This only triggers when an option is selected
										const product = products.find(
											(product) => product.ID === Number(productLabel)
										);
										setSelectedProduct(
											product ||
												(productLabel && { ID: Number(productLabel) })
										);
									}}
								/>
								{selectedProduct ? (
									<Flex
										justify={"center"}
										direction={"column"}
										align="center"
										w={"100%"}
										gap={20}
									>
										<Text
											size="xl"
											mb={10}
											weight={700}
											align="center"
											w={"100%"}
										>
											{config.translate.product[lang]} {selectedProduct.ID}
										</Text>
										{selectedProduct?.desc && (
											<Text
												size="xs"
												weight={300}
												mb={10}
												align="center"
												w={"100%"}
											>
												{parse(selectedProduct.desc || "")}
											</Text>
										)}
										{selectedProduct && (
											<Flex
												align="center"
												justify={"center"}
												w={"100%"}
												gap={20}
											>
												<Card>
													<Text>{config.translate.remain[lang]}</Text>
													<Text c={"blue.6"}>
														{selectedProduct?.weight &&
															new Intl.NumberFormat("en-US").format(
																selectedProduct?.weight
															)}
													</Text>
												</Card>
												<Card>
													<Text>{config.translate.diameter[lang]}</Text>
													<Text c={"blue.6"}>
														{selectedProduct?.diameter &&
															selectedProduct?.diameter}
													</Text>
												</Card>
												<Card>
													<Text>{config.translate.quantity[lang]}</Text>
													<Text c={"blue.6"}>
														{selectedProduct?.quantity &&
															new Intl.NumberFormat("en-US").format(
																selectedProduct?.quantity
															)}
													</Text>
												</Card>
												<Card>
													<Text>{config.translate.price2[lang]}</Text>
													<Text c={"blue.6"}>
														{selectedProduct?.price &&
															formattedNumber(selectedProduct?.price)}
													</Text>
												</Card>
												<Card>
													<Text>{config.translate.price[lang]}</Text>
													<Text c={"blue.6"} fw="bold">
														{selectedProduct?.price &&
															formattedNumber(
																selectedProduct?.price *
																	selectedProduct?.weight
															)}
													</Text>
												</Card>
											</Flex>
										)}
									</Flex>
								) : (
									<Card withBorder>
										<Flex justify="center" align="center" w={"100%"}>
											<Text size="xl" weight={700} align="center" w={"100%"}>
												{config.translate.totals[lang]}
											</Text>
											<Text
												size="xl"
												c={"teal"}
												weight={700}
												align="center"
												w={"100%"}
											>
												{formattedNumber(totalProductsPrice)}$
											</Text>
											<Text size="xl" weight={700} align="center" w={"100%"}>
												{config.translate.products[lang]}
											</Text>
										</Flex>
									</Card>
								)}
							</Grid.Col>
							<Grid.Col span={3}>
								<Group justify="center">
									<Text size="xl" weight={700} w={"100%"} align="center">
										{config.translate.endDate[lang]}
									</Text>
									<DatePicker
										value={endDate}
										onChange={(date) => {
											let dateEnd = new Date(date);
											dateEnd.setHours(0);
											setEndDate(dateEnd);
										}}
										valueFormat="DD/MM/YYYY"
										firstDayOfWeek={6}
									/>
								</Group>
							</Grid.Col>
							<Grid.Col span={12}>
								<Divider my="lg" />
								<Flex
									justify={"center"}
									align="center"
									direction={"column"}
									w={"100%"}
									gap={20}
								>
									{selectedProduct && (
										<>
											<Text size="xl" weight={700} align="center" w={"100%"}>
												{config.translate.totals[lang]}
											</Text>
											<Flex gap={20}>
												<Card>
													<Text>
														{config.translate.productEntry[lang]}
													</Text>
													<Text c={"blue.6"}>
														{new Intl.NumberFormat("en-US").format(
															howManyKGEntryProduct
														)}
													</Text>
												</Card>
												<Card>
													<Text>{config.translate.selled[lang]}</Text>
													<Text c={"orange.6"}>
														{new Intl.NumberFormat("en-US").format(
															howManyKGSelledProduct
														)}
													</Text>
												</Card>
												<Card>
													<Text>{config.translate.remain[lang]}</Text>
													<Text c={"red.6"}>
														{new Intl.NumberFormat("en-US").format(
															howManyKGRemainProduct
														)}
													</Text>
												</Card>
											</Flex>
										</>
									)}
								</Flex>
							</Grid.Col>
							<Divider my="lg" />
							<Grid.Col span={12}>
								{selectedProduct ? (
									<Grid gap={20}>
										<Grid.Col span={12}>
											<AreaChart
												h={300}
												data={productAnalytics}
												dataKey="date"
												series={[
													{ name: "remains", color: "red.6" },
													{ name: "entry", color: "blue.6" },
													{ name: "selled", color: "orange.6" },
												]}
												tooltipProps={{
													content: ({ label, payload }) => (
														<ChartTooltip
															label={label}
															payload={payload.slice(0, 3)}
														/>
													),
												}}
												curveType="linear"
												withBarValueLabel
												valueFormatter={(value) =>
													new Intl.NumberFormat("en-US").format(value)
												}
											/>
										</Grid.Col>
										<Grid.Col span={12}>
											<MantineReactTable table={table3} />
										</Grid.Col>
									</Grid>
								) : (
									<Grid justify="flex-start" align="flex-start">
										<Grid.Col span={6}>
											<Text
												size="xl"
												weight={700}
												align="center"
												mb={20}
												w={"100%"}
											>
												{config.translate.details[lang]} -{" "}
												{config.translate.selledProducts[lang]}
											</Text>
											<MantineReactTable table={table} />
										</Grid.Col>
										<Grid.Col span={6}>
											<Text
												size="xl"
												weight={700}
												align="center"
												mb={20}
												w={"100%"}
											>
												{config.translate.totals[lang]} -{" "}
												{config.translate.selledProducts[lang]}
											</Text>
											<MantineReactTable table={table2} />
										</Grid.Col>
									</Grid>
								)}
							</Grid.Col>
						</Grid>
					</Paper>
				</>
			);
		}
	}
}
