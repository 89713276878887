import React, { useState, useEffect } from "react";
import config from "../../config";
import { useLang } from "../../helpers/language";
import {
	Group,
	Modal,
	Button,
	useMantineColorScheme,
	Input,
	useMantineTheme,
	Grid,
} from "@mantine/core";
import { IconNotification } from "@tabler/icons-react";

export default function Notifications() {
	const { lang } = useLang();
	const theme = useMantineTheme();
	const [opened, setOpened] = useState(false);
	const [expression, setExpression] = useState("");
	const [result, setResult] = useState("");
	const { colorScheme } = useMantineColorScheme();

	// Handle button click for numbers and operators
	const handleButtonClick = (value) => {
		if (value === "C") {
			clearInput();
		} else if (value === "=") {
			calculateResult();
		} else {
			setExpression((prev) => prev + value);
		}
	};

	// Calculate result when pressing the '=' button or 'Enter' key
	const calculateResult = () => {
		try {
			setResult(eval(expression)); // Evaluate the expression
		} catch (error) {
			setResult("Error");
		}
	};

	// Clear the input (when pressing the 'C' button or 'Backspace')
	const clearInput = () => {
		setExpression("");
		setResult("");
	};

	// Handle keyboard events
	const handleKeyPress = (event) => {
		const { key } = event;

		if (!opened) return; // Only handle keypress if the modal is open

		if (key >= "0" && key <= "9") {
			// Handle number keys (0-9)
			handleButtonClick(key);
		} else if (["+", "-", "*", "/"].includes(key)) {
			// Handle operators
			handleButtonClick(key);
		} else if (key === "Enter") {
			// Calculate when pressing Enter
			calculateResult();
		} else if (key === "Backspace") {
			// Clear input when pressing Backspace
			clearInput();
		} else if (key === ".") {
			// Add decimal point
			handleButtonClick(key);
		}
	};

	useEffect(() => {
		// Add event listener to capture key presses
		window.addEventListener("keydown", handleKeyPress);

		return () => {
			// Cleanup event listener on unmount
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [opened, expression]);

	return (
		<>
			<Button
				justify="center"
				width
				onClick={() => {
					setOpened(!opened);
				}}
				p={5}
				size="xs"
				leftSection={
					<IconNotification
						size="1rem"
						color={colorScheme === "dark" ? "white" : "black"}
					/>
				}
				variant="default"
				mt={5}
			>
				{config.translate.calcaulator[lang]}
			</Button>
			<Modal
				opened={opened}
				onClose={() => setOpened(false)}
				title={config.translate.calcaulator[lang]}
				centered
				overlayOpacity={0.55}
				overlayBlur={3}
				size="sm"
				styles={{
					modal: {
						backgroundColor: theme.colorScheme === "dark" ? "#1c1c1e" : "#fff",
						borderRadius: "20px",
						padding: "20px",
					},
				}}
			>
				<div
					style={{
						backgroundColor: "#333",
						color: "#fff",
						textAlign: "right",
						padding: "10px",
						borderRadius: "8px",
						fontSize: "2rem",
						marginBottom: "10px",
					}}
				>
					<Input
						value={expression || "0"}
						disabled
						style={{
							fontSize: "2.5rem",
							textAlign: "right",
							color: "#fff",
							backgroundColor: "transparent",
							border: "none",
							padding: "5px",
						}}
					/>
					<div
						style={{
							color: "#aaa",
							fontSize: "1.5rem",
							marginTop: "5px",
						}}
					>
						{result || " "}
					</div>
				</div>

				<Grid grow gutter="xs">
					{[
						["C", "±", "%", "/"],
						[7, 8, 9, "*"],
						[4, 5, 6, "-"],
						[1, 2, 3, "+"],
						[0, ".", "="],
					].map((row, rowIndex) => (
						<Grid.Col span={12} key={rowIndex}>
							<Group grow>
								{row.map((value) => (
									<Button
										key={value}
										onClick={() =>
											value === "="
												? calculateResult()
												: handleButtonClick(value.toString())
										}
										color={
											typeof value === "number" || value === "."
												? "dark"
												: "orange"
										}
										radius="md"
										size="md"
										fullWidth={value === 0}
										style={{
											backgroundColor:
												typeof value === "number" || value === "."
													? "#505050"
													: value === "="
														? "#ff9500"
														: "#d4d4d2",
											color: value === "=" ? "#fff" : "#000",
											fontSize: "1.5rem",
										}}
									>
										{value}
									</Button>
								))}
							</Group>
						</Grid.Col>
					))}
				</Grid>
			</Modal>
		</>
	);
}
