import * as API from "./api";
import Swal from "sweetalert2";
import config from "../config";
import { notifications } from "@mantine/notifications";
import NProgress from "nprogress";

//   const location = useLocation();

//   let history = useHistory();
//   let editMode;
//   let editedPageTitle = "";
//   const { lang, setLang } = useLang();
//   const { user } = useUser();

//   const interactivePageTitle = () => {
//     let result = "";
//     config.menu.forEach((item) => {
//       if (item.route) {
//         if (location?.pathname.indexOf(item.route) > 0) {
//           result = item.name[lang];
//         }
//       } else {
//         item.subMenu.forEach((innerItem) => {
//           if (location?.pathname.indexOf(innerItem.route) > 0) {
//             result = innerItem.name[lang];
//           }
//         });
//       }
//     });
//     return result;
//   };

//   if (useParams().id === "add") {
//     editMode = false;
//     editedPageTitle = `${
//       config.translate.addNew[lang]
//     } ${interactivePageTitle()}`;
//   } else {
//     editMode = true;
//     editedPageTitle = `${
//       config.translate.edit[lang]
//     } ${interactivePageTitle()}`;
//   }

export const CREATE = async ({
	modelSendToServer,
	route,
	history,
	setBusy,
	lang,
	user,
	filesComponent,
	Status,
	notification,
}) => {
	Swal.fire({
		title: config.translate.areYouSure[lang],
		text: config.translate.youCannotRevertThisAction[lang],
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		confirmButtonText: config.translate.yes[lang],
	}).then(async (result) => {
		if (result.isConfirmed) {
			NProgress.start();
			if (setBusy) setBusy(true);
			modelSendToServer.temporary = false;
			let result;
			try {
				modelSendToServer.history = [
					{
						date: new Date(),
						user: user ? user._id : "",
						desc: config.translate.created,
					},
				];
				result = await API.post(route, modelSendToServer);

				let rawNotifications = await API.post(config.api.notification, notification);

				filesComponent &&
					filesComponent.map(async (component) => {
						if (
							(component.file && component.file.length > 0) ||
							(component.files && component.files.length > 0)
						) {
							try {
								let uploadResult = await API.upload(
									result._id,
									component.files || component.file,
									lang,
									component.destiny,
									component.thumbSizeWidth,
									component.thumbSizeHeight,
									component.responsiveImages,
									component.alt,
									() => {},
									new Date()
								);
								if (uploadResult && result) {
									// if (history) history.push(`/${route}/${result._id}`);
									if (history) history.goBack("");
									notifications.show(
										config.notificationSettings.save("success", lang)
									);
									if (setBusy) setBusy(true);
									return result;
								} else {
									notifications.show(
										config.notificationSettings.save("error", lang)
									);
								}
							} catch (error) {
								console.error(error);
							}
						} else {
							if (result) {
								// if (history) history.push(`/${route}/${result._id}`);
								if (history) history.goBack("");
								notifications.show(
									config.notificationSettings.save("success", lang)
								);
								if (setBusy) setBusy(true);
								return result;
							} else {
								notifications.show(config.notificationSettings.save("error", lang));
							}
						}
						try {
							let uploadResult = await API.upload(
								result._id,
								component.files || component.file,
								lang,
								component.destiny,
								component.thumbSizeWidth,
								component.thumbSizeHeight,
								component.responsiveImages,
								component.alt,
								() => {},
								new Date()
							);
							if (uploadResult && result) {
								// if (history) history.push(`/${route}/${result._id}`);
								if (history) history.goBack("");
								notifications.show(
									config.notificationSettings.save("success", lang)
								);
								if (setBusy) setBusy(true);
								return result;
							} else {
								notifications.show(config.notificationSettings.save("error", lang));
							}
						} catch (error) {
							console.error(error);
						}
					});
			} catch (error) {
				console.error(error);
			}
		}
	});
};
export const UPDATE = async ({
	modelSendToServer,
	route,
	history,
	setBusy,
	id,
	connectedRoute,
	connectedData,
	connectedID,
	lang,
	user,
	filesComponent,
	hardReload,
	Status,
}) => {
	Swal.fire({
		title: config.translate.areYouSure[lang],
		text: config.translate.youCannotRevertThisAction[lang],
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		confirmButtonText: config.translate.yes[lang],
	}).then(async (result) => {
		if (result.isConfirmed) {
			NProgress.start();
			modelSendToServer.temporary = false;
			modelSendToServer.history =
				modelSendToServer?.history && modelSendToServer?.history.length > 0
					? [
							...modelSendToServer?.history,
							{
								date: new Date(),
								user: user ? user._id : null,
								desc: Status ? Status?.name : "",
							},
						]
					: [
							{
								date: new Date(),
								user: user ? user._id : null,
								desc: Status ? Status?.name : "",
							},
						];
			let result;
			try {
				result = await API.update(route, id, modelSendToServer);
				// handle upload files\
				if (filesComponent) {
					filesComponent.map(async (component) => {
						if (
							(component.file && component.file.length > 0) ||
							(component.files && component.files.length > 0)
						) {
							try {
								let uploadResult = await API.upload(
									result._id,
									component.files || component.file,
									lang,
									component.destiny,
									component.thumbSizeWidth,
									component.thumbSizeHeight,
									component.responsiveImages,
									component.alt,
									() => {},
									new Date()
								);
								if (uploadResult && result) {
									if (history) history.goBack("");
									if (hardReload) {
										window.location.reload();
									}
									notifications.show(
										config.notificationSettings.save("success", lang)
									);
									if (setBusy) setBusy(true);
									return result;
								} else {
									notifications.show(
										config.notificationSettings.save("error", lang)
									);
								}
							} catch (error) {
								console.error(error);
							}
						} else {
							if (result) {
								if (history) history.goBack("");
								if (hardReload) {
									window.location.reload();
								}
								notifications.show(
									config.notificationSettings.save("success", lang)
								);
								if (setBusy) setBusy(true);
								return result;
							} else {
								notifications.show(config.notificationSettings.save("error", lang));
							}
						}
					});
				} else {
					try {
						if (result) {
							if (hardReload) {
								window.location.reload();
							}
							if (history) history.goBack("");
							notifications.show(config.notificationSettings.save("success", lang));
							if (setBusy) setBusy(true);
							return result;
						} else {
							notifications.show(config.notificationSettings.save("error", lang));
						}
					} catch (error) {
						console.error(error);
					}
				}
			} catch (error) {
				console.error(error);
			}
		}
	});
};
export const DELETE = async ({ route, setBusy, id, history, lang, isBusy }) => {
	Swal.fire({
		title: config.translate.areYouSure[lang],
		text: config.translate.youCannotRevertThisAction[lang],
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		confirmButtonText: config.translate.yes[lang],
	}).then(async (result) => {
		if (result.isConfirmed) {
			NProgress.start();
			let result;
			try {
				result = await API.remove(route, id);
				NProgress.done();
			} catch (error) {
				console.error(error);
				NProgress.done();
			}
			if (setBusy) setBusy(!isBusy);
			if (history) history.goBack();
			notifications.show(config.notificationSettings.delete("success", lang));
		}
	});
};
