import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbsComponent } from './breadcrumbs.jsx';
import * as API from '../../helpers/api';
import config from '../../config';
import { useUser } from '../../helpers/userContext';
import {
	createTheme,
	MantineProvider,
	AppShell,
	Burger,
	Image,
	Card,
	ScrollArea,
	Flex,
	Breadcrumbs,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Sidebar from './sidebar';
import Navbar from './navbar';
import Calculator from './calculator.jsx';
import Loading from '../modules/loading';
import Footer from './footer';
import { useLocation } from 'react-router-dom';
import { IconLocationSearch } from '@tabler/icons-react';

let firstTimeLoadedSwitch = false;

export default function Main({ component: Component, ...rest }) {
	let [isBusy, setIsBusy] = useState(true);
	const [opened, { toggle }] = useDisclosure();
	const location = useLocation();
	const { user, setUser } = useUser();

	useEffect(() => {
		(async () => {
			let result = {};
			// update user history on server
			// detect if loaded first time

			if (!firstTimeLoadedSwitch) {
				firstTimeLoadedSwitch = true;
				result = await API.update(config.api.user, user._id, {
					...user,
					history: [
						{
							date: new Date(),
							action: {
								method: 'entered',
								name: config.translate.userEntered,
								route: {
									pathname: '/',
									search: '/',
								},
							},
						},
						...user.history.slice(0, 20),
					],
				});
				if (result.history) {
					setUser({
						...user,
						history: result.history,
					});
				}
			} else {
				result = await API.update(config.api.user, user._id, {
					...user,
					history: [
						{
							date: new Date(),
							action: {
								method: 'view',
								name: config.translate.userVisited,
								route: {
									pathname: location.pathname
										.replace(/\/$/, '')
										.replace(/^\//, ''),
									search: location.search.replace(/^\?/, ''),
								},
							},
						},
						...user.history.slice(0, 20),
					],
				});
				if (result.history) {
					setUser({
						...user,
						history: result.history,
					});
				}
			}
			// setUser({
			// 	...user,
			// 	history: result.history ? result.history : user.history,
			// });
		})();
	}, [location]);

	useEffect(() => {
		(async () => {
			setIsBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) {
		return <Loading />;
	} else {
		return (
			<>
				<AppShell
					header={{ height: 60 }}
					navbar={{
						width: 300,
						breakpoint: 'sm',
						collapsed: { mobile: !opened },
					}}
					padding='md'
				>
					<AppShell.Header>
						<Flex
							justify='space-between'
							align='center'
							p={20}
							h={'100%'}
						>
							<Burger
								opened={opened}
								onClick={toggle}
								hiddenFrom='sm'
								size='sm'
							/>
							<Link to='/home'>
								<Image
									src='/logo.svg'
									alt='Mantine logo'
									height={40}
								/>
							</Link>
							<BreadcrumbsComponent
								m={10}
								style={{ textDecoration: 'none' }}
							/>
							<Calculator />
						</Flex>
					</AppShell.Header>

					<AppShell.Navbar p='md'>
						<Sidebar />
					</AppShell.Navbar>

					<AppShell.Main
						style={{
							display: 'flex',
							flexDirection: 'column',
							height: 'calc(100vh - 92px)',
						}}
					>
						<Component user={user} />
					</AppShell.Main>
				</AppShell>
			</>
		);
	}
}
