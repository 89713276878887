import React, { useState, useEffect, useRef } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import { useLang } from "../../helpers/language";
import NProgress, { set } from "nprogress";
import { Table } from "@mantine/core";

export default function Product() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.product;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	const [ID, setID] = useState("");
	const [price, setPrice] = useState(0);
	const [weight, setWeight] = useState(0);
	const [desc, setDesc] = useState("");
	const [quantity, setQuantity] = useState(0);
	const [diameter, setDiameter] = useState("");
	const [taxCode, setTaxCode] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const printRef = useRef(null);

	const modelSendToServer = {
		ID,
		price,
		weight,
		desc,
		quantity,
		diameter,
		taxCode,
	};

	const printStyles = `			<style>
				* {
					margin: 0;
					padding: 0;
					box-sizing: border-box;
				}

				body {
					font-family: Arial, sans-serif;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #f8f8f8;
					flex-direction: column;
					height: fit-content;
					width: 400px;
					border: 1px solid black;
					border-radius: 5px;
					margin: 10px;
					padding: 10px;
				}

				.badge {
					width: 300px;
					height: 300px;
					background-color: white;
					border: 2px solid #ccc;
					border-radius: 10px;
					box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding: 20px;
				}

				.header, .footer {
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					display: flex;
					justify-content: space-between;
					width: 100%;
					padding: 10px;
					background-color: #f0f0f0;
					border-radius: 5px;
					}
					
				.content { 	
					text-align: center;
					font-size: 16px;
					width: 100%;
				}

				.product-table {
					width: 100%;
				}

				.product-table th, .product-table td {
					background-color: #f0f0f0;
					padding: 10px;
					border-radius: 5px;
					text-align: left;
				}

			</style>`;

	useEffect(() => {
		(async () => {
			NProgress.start();

			let rawID = await API.get(route, { temporary: { $ne: true } }, { ID: "desc" }, {}, 1);
			if (id !== "add") {
				let currentItem = await API.get(route, {
					_id: id,
				});

				setID(
					currentItem[0]?.ID ? currentItem[0]?.ID : rawID[0]?.ID ? rawID[0]?.ID + 1 : 1
				);
				setPrice(currentItem[0]?.price && Math.round(currentItem[0]?.price));
				setDiameter(currentItem[0]?.diameter);
				setQuantity(currentItem[0]?.quantity);
				setWeight(parseFloat(currentItem[0]?.weight).toFixed(3));
				setTaxCode(currentItem[0]?.taxCode);
				setDesc(currentItem[0]?.desc);
				setTotalPrice(currentItem[0]?.price * currentItem[0]?.weight);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "product") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<>
				{/* hidden component for printing */}
				<div
					ref={printRef}
					class="badge"
					style={{
						display: "none",
					}}
				>
					<div className="content">
						<Table className="product-table">
							<Table.Thead>
								<Table.Tr>
									<Table.Th>{config.translate.product[lang]}</Table.Th>
									<Table.Th>{config.translate.weight[lang]}</Table.Th>
									<Table.Th>{config.translate.size[lang]}</Table.Th>
									<Table.Th>{config.translate.diameter[lang]}</Table.Th>
								</Table.Tr>
							</Table.Thead>
							<Table.Tbody>
								<Table.Tr>
									<Table.Td>{ID}</Table.Td>
									<Table.Td>{weight}</Table.Td>
									<Table.Td>{quantity}</Table.Td>
									<Table.Td>{diameter}</Table.Td>
								</Table.Tr>
							</Table.Tbody>
						</Table>
					</div>
					<div className="footer">
						<span>
							{config.translate.date[lang]}: {moment().format("DD/MM/YYYY")}
						</span>
					</div>
				</div>
				<SinglePage
					isBusy={isBusy}
					setBusy={setBusy}
					route={route}
					id={id}
					PrintDocument={{
						component: printRef,
						styles: printStyles,
					}}
					permissions={permissions}
					permissionModel="product"
					modelSendToServer={modelSendToServer}
					inputs={[
						{
							value: ID,
							setter: setID,
							type: "number",
							usageType: "number",
							label: "ID",
							permissionModel: "ID",
						},
						{
							value: price,
							setter: (price) => setPrice(Math.round(price)),
							type: "number",
							usageType: "number",
							label: config.translate.price2[lang],
							placeholder: config.translate.price2[lang],
							permissionModel: "price",
						},
						{
							value: weight,
							setter: (weight) => setWeight(parseFloat(weight).toFixed(3)),
							type: "number",
							usageType: "number",
							label: config.translate.weight[lang],
							placeholder: config.translate.weight[lang],
							permissionModel: "weight",
						},
						{
							value: diameter,
							setter: setDiameter,
							type: "textfield",
							usageType: "textfield",
							label: config.translate.diameter[lang],
							placeholder: config.translate.diameter[lang],
							permissionModel: "diameter",
						},
						{
							value: quantity,
							setter: setQuantity,
							type: "number",
							usageType: "number",
							label: config.translate.quantity[lang],
							placeholder: config.translate.quantity[lang],
							permissionModel: "quantity",
						},
						{
							value: taxCode,
							setter: setTaxCode,
							type: "number",
							usageType: "number",
							label: config.translate.taxCode[lang],
							placeholder: config.translate.taxCode[lang],
							permissionModel: "taxCode",
						},
						{
							value: totalPrice,
							disabled: true,
							type: "number",
							usageType: "number",
							label: config.translate.totalPrice[lang],
							placeholder: config.translate.totalPrice[lang],
							permissionModel: "price",
						},
						{
							value: desc,
							setter: setDesc,
							usageType: "textarea",
							label: config.translate.desc[lang],
							placeholder: config.translate.desc[lang],
							permissionModel: "desc",
							width: 12,
						},
					]}
				/>
			</>
		);
}
