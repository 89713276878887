import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import NProgress from "nprogress";

export default function ProductEntry() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.productEntry;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	const [ID, setID] = useState("");
	const [weight, setWeight] = useState(0);
	const [date, setDate] = useState(new Date());
	const [product, setProduct] = useState("");
	const [allProducts, setAllProducts] = useState([]);

	const modelSendToServer = {
		ID,
		weight,
		date,
		product,
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all products
			let products = await API.get(config.api.product, {});
			setAllProducts(products);

			let rawID = await API.get(route, { temporary: { $ne: true } }, { ID: "desc" }, {}, 1);
			if (id !== "add") {
				let currentItem = await API.get(route, {
					_id: id,
				});

				setID(
					currentItem[0]?.ID ? currentItem[0]?.ID : rawID[0]?.ID ? rawID[0]?.ID + 1 : 1
				);
				currentItem[0]?.date && setDate(new Date(currentItem[0]?.date));
				setProduct(currentItem[0]?.product);
				setWeight(currentItem[0]?.weight);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "product") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissions={permissions}
				permissionModel="product-entry"
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: ID,
						setter: setID,
						type: "number",
						usageType: "number",
						label: "ID",
						permissionModel: "ID",
					},
					{
						value: product,
						setter: setProduct,
						type: "optionlist",
						usageType: "optionlist",
						optionListValue: allProducts.map(
							(product) => ({
								value: product._id,
								label: String(product.ID),
							}),
							[]
						),
						label: config.translate.product[lang],
						placeholder: config.translate.product[lang],
						permissionModel: "product",
					},
					product && {
						value: weight,
						setter: (weight) => setWeight(parseFloat(weight).toFixed(3)),
						type: "number",
						usageType: "number",
						label: config.translate.weightMg[lang],
						placeholder: config.translate.weightMg[lang],
						permissionModel: "weight",
					},

					{
						value: date,
						setter: setDate,
						type: "datePicker",
						usageType: "datePicker",
						label: config.translate.date[lang],
						placeholder: config.translate.date[lang],
						permissionModel: "date",
					},
				]}
			/>
		);
}
