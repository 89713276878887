import React, { useState, useEffect, useMemo } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import { useUser } from '../../helpers/userContext';
import moment from 'moment';
import parse from 'html-react-parser';
import { useLang } from '../../helpers/language';
import NProgress from 'nprogress';

const roleParser = (role, field) => {
	if (role === 'manager') {
		return field;
	} else if (role === 'editor') {
		return null;
	} else {
		return field;
	}
};

export default function User() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.user;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	const [index, setIndex] = useState(0);
	const [name, setName] = useState('');
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [desc, setDesc] = useState('');
	const [role, setRole] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState('');
	const [address, setAddress] = useState('');
	const [roles, setRoles] = useState([]);

	const [history, setHistory] = useState([]);
	const [image, setimage] = useState(undefined);

	const modelSendToServer = {
		index,
		name,
		username,
		password,
		desc,
		role,
		email,
		phone,
		dateOfBirth,
		address,
		history,
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			let rawRoles = await API.get(config.api.role);
			if (rawRoles) setRoles(rawRoles);
			if (id !== 'add') {
				let currentItem = await API.get(
					route,
					{
						_id: id,
					},
					null,
					true
				);

				setIndex(currentItem[0]?.index);
				setName(currentItem[0]?.name);
				setPhone(currentItem[0]?.phone);
				setUsername(currentItem[0]?.username);
				setPassword(currentItem[0]?.password);
				setDesc(currentItem[0]?.desc);
				setRole(currentItem[0]?.role);
				setEmail(currentItem[0]?.email);
				currentItem[0]?.dateOfBirth &&
					setDateOfBirth(currentItem[0]?.dateOfBirth);
				setAddress(currentItem[0]?.address);
				setHistory(currentItem[0]?.history);
				setimage(undefined);
				currentItem[0]?.uploads?.map((item) => {
					if (item.destiny === 'image') setimage(item);
					return item;
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === 'user') {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissions={permissions}
				permissionModel='user'
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: 'number',
						usageType: 'number',
						label: config.translate.index[lang],
						placeholder: config.translate.index[lang],
						permissionModel: 'index',
					},
					{
						value: name,
						setter: setName,
						type: 'textfield',
						usageType: 'textfield',
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						permissionModel: 'name',
					},
					{
						value: phone,
						setter: (phoneNumber) => {
							setPhone(
								phoneNumber
									.replace(/^0+/, '')
									.replace(/-/g, '')
									.replace(/\s/g, '')
									.replace(/\(/g, '')
									.replace(/\)/g, '')
							);
						},
						type: 'textfield',
						usageType: 'textfield',
						label: config.translate.phone[lang],
						placeholder: config.translate.phone[lang],
						permissionModel: 'phone',
					},
					{
						value: username,
						setter: setUsername,
						type: 'textfield',
						usageType: 'textfield',
						label: config.translate.username[lang],
						placeholder: config.translate.username[lang],
						permissionModel: 'username',
					},
					{
						value: password,
						setter: setPassword,
						type: 'password',
						usageType: 'password',
						label: config.translate.password[lang],
						placeholder: config.translate.password[lang],
						permissionModel: 'password',
					},
					{
						value: desc,
						setter: setDesc,
						type: 'textarea',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						permissionModel: 'desc',
					},
					{
						value: role,
						setter: setRole,
						optionListValue: (roles || []).map((role) => {
							return {
								label: role.name?.[lang],
								value: role._id,
							};
						}),
						type: 'optionlist',
						usageType: 'optionlist',
						label: config.translate.role[lang],
						permissionModel: 'role',
					},
					{
						value: email,
						setter: setEmail,
						type: 'email',
						usageType: 'textfield',
						label: config.translate.email[lang],
						placeholder: config.translate.email[lang],
						permissionModel: 'email',
					},
					{
						value: dateOfBirth,
						setter: setDateOfBirth,
						type: 'date',
						usageType: 'datePicker',
						label: config.translate.dateOfBirth[lang],
						placeholder: config.translate.dateOfBirth[lang],
						permissionModel: 'dateOfBirth',
					},
					{
						value: address,
						setter: setAddress,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.address[lang],
						placeholder: config.translate.address[lang],
						permissionModel: 'address',
					},
				]}
			/>
		);
}
