import React, { useState, useEffect, useRef } from "react";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import NProgress, { set } from "nprogress";
import { IconCalendar, IconPlus, IconTrash } from "@tabler/icons-react";
import { useParams, useHistory } from "react-router-dom";
import { UPDATE } from "../../helpers/CRUD";
import { DateInput } from "@mantine/dates";
import {
	Autocomplete,
	Badge,
	Button,
	Card,
	Checkbox,
	Divider,
	Flex,
	Grid,
	Group,
	NumberInput,
	Paper,
	Table,
	Text,
} from "@mantine/core";
import Swal from "sweetalert2";
import Input from "../modules/input";

let formattedNumber = (number) =>
	new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(number);

export default function Order() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.order;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);
	const history = useHistory();

	const [ID, setID] = useState("");
	const [client, setClient] = useState("");
	const [price, setPrice] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const [paid, setPaid] = useState(0);
	const [debt, setDebt] = useState(0);
	const [status, setStatus] = useState("");
	const [desc, setDesc] = useState("");
	const [date, setDate] = useState(new Date());
	const [cart, setCart] = useState([]);
	const [productAutocompleteValue, setProductAutocompleteValue] = useState("");

	//selected values
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedWeight, setSelectedWeight] = useState(null);
	const [selectedDiameter, setSelectedDiameter] = useState(null);
	const [selectedQuantity, setSelectedQuantity] = useState(null);

	const [selectedRows, setSelectedRows] = useState([]);
	// use for selects
	const [allProducts, setAllProducts] = useState([]);
	const [allClients, setAllClients] = useState([]);

	const [selectedProductPrice, setSelectedProductPrice] = useState(0);
	const [selectedProdcutDiscount, setSelectedProductDiscount] = useState(0);

	let rows = cart?.map(
		({ product, weight, diameter, price, totalPrice, quantity, difference }, index) => {
			price = Math.round(price);
			totalPrice = Math.round(totalPrice);
			weight = parseFloat(weight)?.toFixed(3);
			return (
				<Table.Tr
					key={index}
					bg={
						selectedRows.includes(product.ID)
							? "var(--mantine-color-blue-light)"
							: undefined
					}
				>
					<Table.Td>
						<Checkbox
							aria-label="Select row"
							checked={selectedRows.includes(index)}
							onChange={(event) =>
								// write logic without product.ID, the same product can be in different rows
								// so we need to check if the product is in the cart
								// use index to get the product from the cart
								setSelectedRows(
									selectedRows.includes(index)
										? selectedRows.filter((i) => i !== index)
										: [...selectedRows, index]
								)
							}
						/>
					</Table.Td>
					<Table.Td>{product.ID}</Table.Td>
					<Table.Td>{weight}</Table.Td>
					<Table.Td>{diameter}</Table.Td>
					<Table.Td>{quantity}</Table.Td>
					<Table.Td>{price}</Table.Td>
					<Table.Td>
						<NumberInput
							label={config.translate.totalPrice[lang]}
							w={100}
							placeholder={config.translate.totalPrice[lang]}
							value={totalPrice || 0}
							onChange={(newPrice) => {
								let newCart = cart.map((item, i) => {
									if (i === index) {
										return {
											...item,
											totalPrice: Math.round(newPrice),
											weight: Number(item.weight).toFixed(3),
											difference: Math.round(
												-1 * (item.product.price * item.weight - newPrice)
											),
										};
									}
									return item;
								});
								setCart(newCart);
							}}
						/>
					</Table.Td>
					{difference !== 0 && (
						<Table.Td>
							<Badge color={difference < 0 ? "red.6" : "green.6"} size="lg">
								{formattedNumber(difference)}
							</Badge>
						</Table.Td>
					)}
				</Table.Tr>
			);
		}
	);

	const componentRef = useRef();

	const handlePrint = () => {
		const printContent = componentRef.current;
		const newWindow = window.open("", "", "width=480,height=280");

		newWindow.document.write(`
		  <html>
		<head>
			<title>Print Badge</title>
			<style>
				/* Add print-specific styles */
				.page {
					list-style-type: none;
					margin: 0;
					padding: 0;
					font-family: Arial, sans-serif;
					font-weight: 800;
				}

			</style>
        </head>
			<body>${printContent?.innerHTML}</body>
		  </html>
		`);
		newWindow.document.close();

		// Give time for the new window to render before printing
		setTimeout(() => {
			newWindow.focus();
			newWindow.print();
			newWindow.close();
		}, 500); // 500ms delay for rendering
	};

	const modelSendToServer = {
		ID,
		client: client?._id,
		cart,
		price: price ? price : 0,
		totalPrice: totalPrice ? totalPrice : 0,
		status,
		desc,
		debt: debt ? debt : 0,
		paid: paid ? paid : 0,
		date,
	};

	const addToCart = ({ product, weight, diameter, price, totalPrice, quantity, difference }) => {
		const updatedCart = [
			...cart,
			{
				product,
				weight,
				diameter,
				quantity,
				price: Math.round(price),
				totalPrice: Math.round(totalPrice),
				difference: Math.round(difference),
			},
		];
		setCart(updatedCart);
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all users
			let rawclients = await API.get(config.api.client, {
				temporary: { $ne: true },
			});
			setAllClients(rawclients);
			// all products
			let rawProducts = await API.get(config.api.product, {
				temporary: { $ne: true },
			});
			setAllProducts(rawProducts);

			let rawID = await API.get(route, { temporary: { $ne: true } }, { ID: "desc" }, {}, 1);

			if (id !== "add") {
				let currentItem = await API.get(
					route,
					{
						_id: id,
					},
					null,
					null,
					null,
					["client", "cart.product"]
				);

				setID(
					currentItem[0]?.ID ? currentItem[0]?.ID : rawID[0]?.ID ? rawID[0]?.ID + 1 : 1
				);
				setCart(
					currentItem[0]?.cart && Array.isArray(currentItem[0]?.cart)
						? currentItem[0]?.cart
						: []
				);
				currentItem[0]?.date && setDate(new Date(currentItem[0]?.date));
				setClient(currentItem[0]?.client);
				setPrice(currentItem[0]?.price);
				setTotalPrice(currentItem[0]?.totalPrice);
				setStatus(currentItem[0]?.status);
				setDesc(currentItem[0]?.desc ? currentItem[0]?.desc : "");
				setDebt(currentItem[0]?.debt);
				setPaid(currentItem[0]?.paid);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "order") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	useEffect(() => {
		let tempPrice = cart.reduce((acc, { totalPrice }) => acc + totalPrice, 0);
		tempPrice = Math.round(tempPrice);
		setPrice(tempPrice);
		setPaid(isNaN(paid) ? 0 : paid);
		setDebt(isNaN(tempPrice - paid) ? 0 : tempPrice - paid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [price, paid, cart, cart.length]);

	useEffect(() => {}, [selectedRows, rows, paid, selectedProduct]);

	if (isBusy) return <Loading />;
	else
		return (
			<>
				<Paper withBorder p="md" radius="md" shadow="xs">
					<Grid gutter="md" columns={12} justify="center" align="center">
						<Grid.Col
							span={12}
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<Card w={"100%"} mr={10}>
								<Text
									weight={700}
									style={{
										textAlign: "center",
									}}
								>
									{config.translate.order[lang]} {ID}
								</Text>
								<Autocomplete
									data={allClients.map((client) => ({
										value: client._id,
										label: client.name,
									}))}
									onSearchChange={(searchValue) => {
										// Handle input typing for search
										setClient({ name: searchValue });
									}}
									onChange={(val) => {
										// Update `client` only when an option is selected
										const selectedClient = allClients.find(
											(client) => client.name === val
										);
										setClient(selectedClient || { name: val });
									}}
									value={client?.name || ""}
									label={config.translate.client[lang]}
									placeholder={config.translate.client[lang]}
								/>
							</Card>
							<Card w={"100%"} ml={10}>
								<Text
									weight={700}
									style={{
										textAlign: "center",
									}}
								>
									{config.translate.date[lang]}
								</Text>

								<DateInput
									value={date}
									onChange={(date) => setDate(date)}
									valueFormat="DD/MM/YYYY"
									label={config.translate.date[lang]}
									placeholder={config.translate.date[lang]}
									leftSection={<IconCalendar size={16} />}
								/>
							</Card>
						</Grid.Col>
						<Divider my="lg" />
						<Grid.Col span={12}>
							<Flex gap={20} align={"end"}>
								<Button
									disabled={selectedRows.length === 0}
									color="red"
									onClick={() => {
										let newCart = cart.filter(
											(item, index) => !selectedRows.includes(index)
										);
										setCart(newCart);
										setPrice(
											newCart.reduce(
												(acc, { totalPrice }) => acc + totalPrice,
												0
											)
										);
										setSelectedRows([]);
									}}
								>
									<IconTrash size={20} />
								</Button>

								{/* hidden component for printing */}
								<div
									ref={componentRef}
									className="badge"
									style={{
										display: "none",
									}}
								>
									<div className="print-content">
										{cart.map(
											({
												product,
												weight,
												diameter,
												quantity,
												price,
												totalPrice,
											}) => (
												<ul className="page">
													<li>
														{config.translate.quantity[lang]} -{" "}
														{quantity}
													</li>
													<li>
														{config.translate.size[lang]} - {diameter}{" "}
														mm
													</li>
													<li>
														{config.translate.weight[lang]} - {weight}{" "}
														carat
													</li>
													<li>
														{config.translate.price[lang]} -{" "}
														{Math.round(totalPrice)}{" "}
													</li>
												</ul>
											)
										)}
									</div>
								</div>

								<Autocomplete
									label={config.translate.selectProduct[lang]}
									placeholder={config.translate.selectProduct[lang]}
									data={allProducts.flatMap((product) => {
										return {
											value: product._id,
											label: String(product.ID),
										};
									})}
									onChange={(val) => {
										let product = allProducts.find(
											(product) => product?.ID === Number(val)
										);
										setSelectedProductPrice(Math.round(product?.price));
										setSelectedProduct(product || { ID: val });
										setProductAutocompleteValue(product?.ID);
									}}
									onSearchChange={(searchValue) => {
										// Handle input typing for search
										setSelectedProduct({ ID: searchValue });
									}}
									value={selectedProduct?.ID || ""}
								/>

								<NumberInput
									label={config.translate.weight[lang]}
									placeholder={config.translate.weight[lang]}
									value={selectedWeight || 0}
									onChange={(weight) => {
										if (!weight || typeof weight === "string") return;
										setSelectedWeight(
											weight ? parseFloat(weight).toFixed(3) : 0
										);
									}}
									w={100}
								/>

								<NumberInput
									label={config.translate.diameter[lang]}
									placeholder={config.translate.diameter[lang]}
									value={selectedDiameter || 0}
									onChange={setSelectedDiameter}
									w={100}
								/>
								<NumberInput
									label={config.translate.quantity[lang]}
									placeholder={config.translate.quantity[lang]}
									value={selectedQuantity || 0}
									onChange={setSelectedQuantity}
									w={100}
								/>

								<NumberInput
									label={config.translate.price2[lang]}
									placeholder={config.translate.price2[lang]}
									value={selectedProductPrice || 0}
									onChange={(price) => {
										setSelectedProductPrice(Math.round(price));
									}}
									disabled={!selectedProduct}
									w={100}
								/>

								<Button
									disabled={
										!(
											client &&
											selectedProduct &&
											selectedWeight &&
											selectedDiameter
										)
									}
									onClick={() => {
										if (
											!client ||
											!selectedProduct ||
											!selectedWeight ||
											!selectedDiameter
										) {
											Swal.fire({
												icon: "error",
												title: "Oops...",
												text: "Please fill all fields",
											});
											return;
										}
										addToCart({
											product: selectedProduct,
											weight: parseFloat(selectedWeight).toFixed(3),
											diameter: selectedDiameter,
											quantity: selectedQuantity,
											price: Math.round(selectedProductPrice),
											totalPrice: Math.round(
												selectedProductPrice * selectedWeight
											),
											difference: Math.round(
												(selectedProduct.price * selectedWeight -
													selectedProductPrice * selectedWeight) *
													-1
											),
										});
										setProductAutocompleteValue("");
										setSelectedProduct(null);
										setSelectedProductPrice(0);
										setSelectedProductDiscount(0);
										setSelectedWeight(null);
										setSelectedDiameter(null);
										setSelectedQuantity(null);
									}}
								>
									<IconPlus size={20} />
								</Button>

								{/* Print button */}
								<Button
									onClick={handlePrint}
									variant="light"
									color="blue"
									disabled={!client || !cart || cart.length === 0}
								>
									{config.translate.print[lang]}
								</Button>
							</Flex>
						</Grid.Col>
						<Divider my="lg" />
						<Divider my="xs" />
						<Table>
							<Table.Thead>
								<Table.Tr>
									<Table.Th></Table.Th>
									<Table.Th>{config.translate.product[lang]}</Table.Th>
									<Table.Th>{config.translate.weight[lang]}</Table.Th>
									<Table.Th>{config.translate.diameter[lang]}</Table.Th>
									<Table.Th>{config.translate.quantity[lang]}</Table.Th>
									<Table.Th>{config.translate.price2[lang]}</Table.Th>
									<Table.Th>{config.translate.price[lang]}</Table.Th>
									<Table.Th>{config.translate.difference[lang]}</Table.Th>
								</Table.Tr>
							</Table.Thead>
							<Table.Tbody>{rows}</Table.Tbody>
						</Table>
						<Divider my="lg" />
						<Grid.Col span={12}>
							<Flex justify={"space-between"}>
								<Card w={"100%"}>
									<Text weight={700}>{config.translate.paid[lang]}</Text>
									<Divider my="sm" />
									<NumberInput
										disabled={price === 0 || !price}
										value={paid}
										onChange={setPaid}
									/>
								</Card>
								<Card w={"100%"} ml={10} mr={10}>
									<Text weight={700}>{config.translate.debt[lang]}</Text>
									<Divider my="sm" />
									<Text weight={700} c={"red"} size="xl">
										{debt}
									</Text>
								</Card>
								<Card w={"100%"}>
									<Text weight={700}>{config.translate.totalPrice[lang]}</Text>
									<Divider my="sm" />
									<Text weight={700} c={"cyan"} size="xl">
										{price}
									</Text>
								</Card>
							</Flex>
						</Grid.Col>
						<Grid.Col span={12}>
							<Input
								label={config.translate.desc[lang]}
								placeholder={config.translate.desc[lang]}
								value={desc}
								setter={setDesc}
								type="textarea"
								usageType="textarea"
								crud={
									permissions.filter(
										(permission) => permission.name === "desc"
									)[0]
								}
							/>
						</Grid.Col>
					</Grid>
				</Paper>

				<Group justify="center" mt="xl">
					<Button variant="default" onClick={() => history.goBack()}>
						{config.translate.cancel[lang]}
					</Button>
					<Button
						disabled={!client || !cart || cart.length === 0}
						onClick={async () => {
							let result = await UPDATE({
								route,
								id,
								modelSendToServer,
								setBusy,
								history,
								lang,
							});
						}}
					>
						{config.translate.save[lang]}
					</Button>
				</Group>
			</>
		);
}
