import { Progress, Tooltip } from "@mantine/core";
import React, { useState, useEffect } from "react";
import config from "../../config";
import { useLang } from "../../helpers/language";

export default function Loading({ progress }) {
  const { lang } = useLang();
  return null
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(5px) saturate(120%)",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 9999999999,
        backgroundColor: "rgba($black, 0.5)",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "calc(50% - 20px)",
          textShadow: "0 0 black",
          textAlign: "center",
          width: "50%",
          right: "24%",
          fontSize: "2em",
          // opacity: 0.5,
        }}
      >
        <Progress.Root size={40}>
          <Tooltip label={config.translate.loading[lang]}>
            <Progress.Section value={progress} color="cyan">
              <Progress.Label>{progress} %</Progress.Label>
            </Progress.Section>
          </Tooltip>
        </Progress.Root>
      </div>
    </div>
  );
}
