import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
// import Navigation from './navigation';
import { useAuth } from "../../helpers/auth";
import config from "../../config";
import { ActionToggle } from "../../helpers/actionToggle/actionToggle";
import { useUser } from "../../helpers/userContext";
import { useLang, LanguagePicker } from "../../helpers/language";
import { useDisclosure } from "@mantine/hooks";
import {
	IconArrowRight,
	IconBell,
	IconChevronRight,
	IconFolder,
	IconLogin,
	IconMessageDots,
	IconNotification,
	IconRefresh,
	IconTicket,
	IconTrash,
} from "@tabler/icons-react";
import { useViewportSize } from "@mantine/hooks";

import {
	Avatar,
	Text,
	Group,
	Card,
	Grid,
	Modal,
	Button,
	List,
	Image,
	Flex,
	NavLink,
	ScrollArea,
	useMantineColorScheme,
	Timeline,
} from "@mantine/core";
import { IconPhoneCall, IconAt, IconHistory, IconLogout, IconSettings } from "@tabler/icons-react";
import classes from "./UserInfoIcons.module.css";
import { TimelineComponent } from "../modules/timelineComponent";
import moment from "moment";

export default function Sidebar() {
	const { logout } = useAuth();
	const location = useLocation();
	const { user } = useUser();
	const { lang } = useLang();
	const history = useHistory();
	const [opened, OpenClose] = useDisclosure(false);
	const [opened1, OpenClose1] = useDisclosure(false);
	const { colorScheme } = useMantineColorScheme();
	const { height, width } = useViewportSize();
	const [active, setActive] = useState(0);
	const [subActive, setSubActive] = useState(0);

	return (
		<div className="sidebar">
			<Card shadow="sm" padding="lg">
				<Grid>
					<Modal
						opened={opened}
						onClose={OpenClose.close}
						title={config.translate.settings[lang]}
						centered
					>
						<Card withBorder shadow="sm" radius="md">
							<Card.Section withBorder inheritPadding py="xs">
								{config.translate.languagePickerLabel[lang]}
							</Card.Section>
							<Card.Section withBorder inheritPadding py="xs">
								<LanguagePicker />
							</Card.Section>
						</Card>

						<Card withBorder shadow="sm" radius="md" mt={20}>
							<Card.Section withBorder inheritPadding py="xs">
								{config.translate.themePickerLabel[lang]}
							</Card.Section>
							<Card.Section withBorder inheritPadding py="xs">
								<ActionToggle />
							</Card.Section>
						</Card>
					</Modal>
					<Modal
						opened={opened1}
						onClose={OpenClose1.close}
						title={config.translate.history[lang]}
						centered
					>
						{user.history && user.history?.length > 0 && (
							<Card withBorder shadow="sm" radius="md">
								<Timeline
									active={user.history.length}
									bulletSize={24}
									lineWidth={2}
								>
									{user?.history?.map((item, index) => {
										return (
											<Timeline.Item
												bullet={
													item.action.method === "entered" ? (
														<IconLogin size={14} />
													) : item.action.method === "updated" ? (
														<IconRefresh size={14} />
													) : item.action.method === "deleted" ? (
														<IconTrash size={14} />
													) : (
														<IconArrowRight size={14} />
													)
												}
												title={
													moment(item?.date).format("HH:mm") +
														" - " +
														item?.action?.name?.[lang] ||
													"No description"
												}
												key={index}
											>
												{item?.action?.route.pathname && (
													<Link
														to={
															"/" +
															item?.action?.route.pathname +
															"?" +
															item?.action?.route.search
														}
														onClick={OpenClose1.close}
													>
														<Text c="dimmed" size="md">
															{item?.action?.route.pathname}
														</Text>
														<Text c="dimmed" size="md">
															{item?.action?.route.search}
														</Text>
													</Link>
												)}
											</Timeline.Item>
										);
									})}
								</Timeline>
							</Card>
						)}
					</Modal>
					<Grid.Col span={5}>
						<Avatar
							src={`${config.api.API_URL}/${user.uploads?.path}`}
							size={94}
							radius="md"
						/>
					</Grid.Col>
					<Grid.Col span={7}>
						<Flex wrap="nowrap" mt={1} direction={"column"}>
							<Button
								justify="center"
								width
								onClick={logout}
								p={5}
								size="xs"
								leftSection={
									<IconLogout
										size="1rem"
										className={classes.icon}
										color={colorScheme === "dark" ? "white" : "black"}
									/>
								}
								variant="default"
							>
								{config.translate.logOut[lang]}
							</Button>

							<Button
								justify="center"
								width
								onClick={OpenClose1.toggle}
								p={5}
								size="xs"
								leftSection={
									<IconHistory
										size="1rem"
										className={classes.icon}
										color={colorScheme === "dark" ? "white" : "black"}
									/>
								}
								variant="default"
								mt={5}
							>
								{config.translate.history[lang]}
							</Button>

							<Button
								justify="center"
								width
								size="xs"
								onClick={OpenClose.toggle}
								p={5}
								leftSection={
									<IconSettings
										size="1rem"
										className={classes.icon}
										color={colorScheme === "dark" ? "white" : "black"}
									/>
								}
								variant="default"
								mt={5}
							>
								{config.translate.settings[lang]}
							</Button>
						</Flex>
					</Grid.Col>
					<Grid.Col span={12}>
						<div>
							<Text fz="lg" fw={500} className={classes.name}>
								{user.name?.[lang]}
							</Text>
							<Text fz="xs" tt="uppercase" fw={700} c="dimmed">
								{user.position?.name?.[lang]}
							</Text>
						</div>
					</Grid.Col>
				</Grid>
			</Card>

			<Card shadow="sm" padding="" mt={20}>
				<ScrollArea h={height - 40 - 267}>
					{config.menu.flatMap((item, index) => {
						if (item.subMenu) {
							return (
								<NavLink
									href="#required-for-focus"
									onClick={() => {
										setActive(index);
										setSubActive(null);
										if (!item.subMenu) {
											return history.push(`/${item.route}`);
										}
									}}
									label={item.title[lang]}
									key={index}
									childrenOffset={10}
									leftSection={<IconFolder size="1.5rem" stroke={1.5} />}
									rightSection={
										<IconChevronRight
											size="0.8rem"
											stroke={1.5}
											className="mantine-rotate-rtl"
										/>
									}
									variant="subtle"
									active={index === active && subActive === null}
								>
									{item.subMenu?.flatMap((subItem, subIndex) => {
										if (user.ananunaki) {
											return (
												<NavLink
													label={
														subItem.name[lang]
															?.charAt(0)
															.toUpperCase() +
														subItem.name[lang]?.slice(1)
													}
													onClick={() => {
														setActive(index);
														setSubActive(subIndex);
														history.push(`/${subItem.route}`);
													}}
													leftSection={
														<Image
															src={`/assets/${subItem.icon}`}
															alt="navigation-icon"
															w={20}
															h={20}
															style={{
																filter: `invert(${
																	colorScheme === "dark" ? 1 : 0
																})`,
															}}
														/>
													}
													active={
														index === active && subIndex === subActive
													}
													key={subIndex}
												/>
											);
										} else {
											return user.role?.permissions?.map((perm) => {
												if (
													perm.name === subItem.permissionModel &&
													perm.crud.read
												) {
													return (
														<NavLink
															label={
																subItem.name[lang]
																	?.charAt(0)
																	.toUpperCase() +
																subItem.name[lang]?.slice(1)
															}
															onClick={() => {
																setActive(index);
																setSubActive(subIndex);
																history.push(`/${subItem.route}`);
															}}
															leftSection={
																<Image
																	src={`/assets/${subItem.icon}`}
																	alt="navigation-icon"
																	w={20}
																	h={20}
																	style={{
																		filter: `invert(${
																			colorScheme === "dark"
																				? 1
																				: 0
																		})`,
																	}}
																/>
															}
															active={
																index === active &&
																subIndex === subActive
															}
															key={subIndex}
														/>
													);
												}
												return null;
											});
										}
									})}
								</NavLink>
							);
						} else {
							if (user.ananunaki) {
								return (
									<NavLink
										href="#required-for-focus"
										onClick={() => {
											setActive(index);
											setSubActive(null);
											if (!item.subMenu) {
												return history.push(`/${item.route}`);
											}
										}}
										label={item.title[lang]}
										key={index}
										childrenOffset={10}
										leftSection={<IconFolder size="1.5rem" stroke={1.5} />}
										rightSection={
											<IconChevronRight
												size="0.8rem"
												stroke={1.5}
												className="mantine-rotate-rtl"
											/>
										}
										variant="subtle"
										active={index === active && subActive === null}
									>
										{item.subMenu?.flatMap((subItem, subIndex) => {
											return (
												<NavLink
													label={
														subItem.name[lang]
															?.charAt(0)
															.toUpperCase() +
														subItem.name[lang]?.slice(1)
													}
													onClick={() => {
														setActive(index);
														setSubActive(subIndex);
														history.push(`/${subItem.route}`);
													}}
													leftSection={
														<Image
															src={`/assets/${subItem.icon}`}
															alt="navigation-icon"
															w={20}
															h={20}
															style={{
																filter: `invert(${
																	colorScheme === "dark" ? 1 : 0
																})`,
															}}
														/>
													}
													active={
														index === active && subIndex === subActive
													}
													key={subIndex}
												/>
											);
										})}
									</NavLink>
								);
							} else {
								return user.role?.permissions?.map((perm) => {
									if (perm.name === item.permissionModel && perm.crud.read) {
										return (
											<NavLink
												href="#required-for-focus"
												onClick={() => {
													setActive(index);
													setSubActive(null);
													if (!item.subMenu) {
														return history.push(`/${item.route}`);
													}
												}}
												label={item.title[lang]}
												key={index}
												childrenOffset={10}
												leftSection={
													<Image
														src={`/assets/${item.icon}`}
														alt="navigation-icon"
														w={20}
														h={20}
														style={{
															filter: `invert(${
																colorScheme === "dark" ? 1 : 0
															})`,
														}}
													/>
												}
												variant="subtle"
												active={index === active && subActive === null}
											>
												{item.subMenu?.flatMap((subItem, subIndex) => {
													return (
														<NavLink
															label={
																subItem.name[lang]
																	?.charAt(0)
																	.toUpperCase() +
																subItem.name[lang]?.slice(1)
															}
															onClick={() => {
																setActive(index);
																setSubActive(subIndex);
																history.push(`/${subItem.route}`);
															}}
															leftSection={
																<Image
																	src={`/assets/${subItem.icon}`}
																	alt="navigation-icon"
																	w={20}
																	h={20}
																	style={{
																		filter: `invert(${
																			colorScheme === "dark"
																				? 1
																				: 0
																		})`,
																	}}
																/>
															}
															active={
																index === active &&
																subIndex === subActive
															}
															key={subIndex}
														/>
													);
												})}
											</NavLink>
										);
									}
									return null;
								});
							}
						}
					})}
				</ScrollArea>
			</Card>
		</div>
	);
}
