import React, { useState, useEffect, Suspense } from "react";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "mantine-react-table/styles.css"; //import MRT styles
import { createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import "@mantine/tiptap/styles.css";

// Helpers
import history from "./helpers/history";
import { cookies, setCookies } from "./helpers/cookies";
import { isLoggedIn } from "./helpers/auth";
import { UserContext } from "./helpers/userContext";
import API from "./helpers/api";
import { useStateWithLocalStorage } from "./helpers/storage";
import config from "./config";
import Loading from "./components/modules/loading.jsx";
import { Language } from "./helpers/language";
import { PermissionContext } from "./helpers/permissionsContext.js";

// global components
import Login from "./components/login";
import Main from "./components/main/main";

// importing styles for chart js
import "@mantine/charts/styles.css";

//Routing
import { Router, Route, Switch } from "react-router-dom";

// Components
import Home from "./components/home";
import Page404 from "./components/Page404";
import UserPage from "./components/user/user";
import UserList from "./components/user/userList";
import Role from "./components/role/role";
import Roles from "./components/role/roles";
import Permissions from "./components/permissions/permissions";
import Product from "./components/product/product.jsx";
import ProductList from "./components/product/productList.jsx";
import ProductAnalyticsList from "./components/product-analytics/productAnalyticsList.jsx";
import ProductEntry from "./components/product-entry/productEntry.jsx";
import ProductEntryList from "./components/product-entry/productEntryList.jsx";
import Client from "./components/client/client.jsx";
import ClientList from "./components/client/clientList.jsx";
import ClientAnalyticsList from "./components/client-analytics/clientAnalyticsList.jsx";
import Debt from "./components/debt/debt.jsx";
import DebtList from "./components/debt/debtList.jsx";
import DebtRepayment from "./components/debt-repayment/debtRepayment.jsx";
import DebtRepaymentList from "./components/debt-repayment/debtRepaymentList.jsx";
import Encashment from "./components/encashment/encashment.jsx";
import EncashmentList from "./components/encashment/encashmentList.jsx";
import Order from "./components/order/order.jsx";
import OrderList from "./components/order/orderList.jsx";

function RouterComponent({ user }) {
	if (user) {
		return (
			<Suspense fallback={<Loading />}>
				<Switch>
					<Route exact path="/home" component={Home} />
					<Route exact path="/user/:id" component={UserPage} />
					<Route exact path="/user" component={UserList} />
					<Route exact path="/product/:id" component={Product} />
					<Route exact path="/product" component={ProductList} />
					<Route exact path="/product-analytics" component={ProductAnalyticsList} />
					<Route exact path="/product-entry/:id" component={ProductEntry} />
					<Route exact path="/product-entry" component={ProductEntryList} />
					<Route exact path="/client/:id" component={Client} />
					<Route exact path="/client" component={ClientList} />
					<Route exact path="/client-analytics" component={ClientAnalyticsList} />
					<Route exact path="/debt/:id" component={Debt} />
					<Route exact path="/debt" component={DebtList} />
					<Route exact path="/debt-repayment/:id" component={DebtRepayment} />
					<Route exact path="/debt-repayment" component={DebtRepaymentList} />
					<Route exact path="/encashment/:id" component={Encashment} />
					<Route exact path="/encashment" component={EncashmentList} />
					<Route exact path="/order/:id" component={Order} />
					<Route exact path="/order" component={OrderList} />
					<Route exact path="/role" component={Roles} />
					<Route exact path="/role/:id" component={Role} />
					<Route exact path="/permission" component={Permissions} />
				</Switch>
			</Suspense>
		);
	} else {
		return (
			<Suspense>
				<Route path="*" component={Page404} />
			</Suspense>
		);
	}
}

const theme = createTheme(
	{
		shadows: {
			md: "1px 1px 3px rgba(0, 0, 0, .25)",
			xl: "5px 5px 3px rgba(0, 0, 0, .25)",
		},
		primaryColor: "blue",
		fontFamily: "Roboto, sans-serif",
	},
	{
		links: {
			textDecoration: "none",
			color: "inherit",
		},
		a: {
			textDecoration: "none",
			color: "inherit",
		},
	}
);

function App() {
	let [loggedIn, setLoggedIn] = useState(Boolean);
	let [user, setUser] = useState(Boolean);
	const [permissions, setPermissions] = useState([]);
	let [lang, setLang] = useStateWithLocalStorage("language");
	const [colorScheme, setColorScheme] = useStateWithLocalStorage("mantine-color-scheme-value");

	function logout() {
		setCookies("");
		setLoggedIn(false);
		cookies.remove("authorization");
	}

	function login(username, password) {
		if (username && password) {
			return API.post(config.authentification.login, {
				username,
				password,
			})
				.then((res) => {
					if (res.status === 200) {
						setCookies(res.data.token);
						setLoggedIn(true);
						setUser(res.data.user);
						setPermissions(res.data.permissions);
					}
				})
				.catch((error) => {
					console.error(error);
					logout();
					return false;
				});
		} else {
			logout();
			console.log("There is no user with provided username & password");
		}
	}

	async function checkToken() {
		let token = await cookies.get(config.authentification.tokenAddress);
		if (token) {
			API.post(config.authentification.verify, {
				token,
			})
				.then((res) => {
					if (res.status === 200) {
						setCookies(token);
						setLoggedIn(true);
						setUser(res.data.user);
						setPermissions(res.data.permissions);
					} else {
						setLoggedIn(false);
					}
				})
				.catch((res) => {
					console.error(res);
					console.error(`Please don't edit cookies manually`);
					logout();
				});
		} else {
			logout();
			console.log(
				"You must provide token for calling login -> veryfyToken functions, Or mongodb database is not running"
			);
			return false;
		}
	}

	const showNotification = () => {
		// create a new notification
		const notification = new Notification("JavaScript Notification API", {
			body: "This is a JavaScript Notification API demo",
			icon: "/assets/black.png",
			vibrate: true,
		});

		// close the notification after 10 seconds
		setTimeout(() => {
			notification.close();
		}, 10 * 1000);

		// history to a URL
		notification.addEventListener("click", () => {
			window.open(
				"https://www.javascripttutorial.net/web-apis/javascript-notification/",
				"_blank"
			);
		});
	};

	let granted = false;
	// show an error message
	const showError = () => {
		const error = document.querySelector(".error");
		if (error) {
			error.style.display = "block";
			error.textContent = "You blocked the notifications";
		}
	};

	useEffect(() => {
		(async () => {
			if (lang === "" || lang === null) {
				setLang("en");
			}
			if (colorScheme === "" || colorScheme === null) {
				setColorScheme("light");
			}
			await checkToken();

			if (Notification.permission === "granted") {
				granted = true;
			} else if (Notification.permission !== "denied") {
				let permission = await Notification.requestPermission();
				granted = permission === "granted" ? true : false;
			}
			granted ? showNotification() : showError();
			// eslint-disable-next-line
		})();
	}, []);

	if (loggedIn && user) {
		return (
			<PermissionContext.Provider value={{ permissions, setPermissions }}>
				<Language.Provider value={{ lang, setLang, colorScheme, setColorScheme }}>
					<isLoggedIn.Provider value={{ login, logout, loggedIn }}>
						<UserContext.Provider value={{ user, setUser }}>
							<MantineProvider theme={theme}>
								<Router history={history}>
									<Main component={RouterComponent} />
								</Router>
							</MantineProvider>
						</UserContext.Provider>
					</isLoggedIn.Provider>
				</Language.Provider>
			</PermissionContext.Provider>
		);
	} else {
		return (
			<Language.Provider value={{ lang, setLang }}>
				<isLoggedIn.Provider value={{ login, logout, loggedIn }}>
					<MantineProvider theme={theme}>
						<Notifications position="top-right" zIndex={1000} />
						<Router history={history}>
							<Route component={Login} />
						</Router>
					</MantineProvider>
				</isLoggedIn.Provider>
			</Language.Provider>
		);
	}
}

export default App;
