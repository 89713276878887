import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress from "nprogress";
import { useLang } from "../../helpers/language";
import { Image, useMantineColorScheme } from "@mantine/core";
import moment from "moment";

const parsePhoneNumber = (number) => {
	return `+374${number.split(/[-*\/\s]+/).join("")}`;
};

export default function ClientList() {
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [debts, setBedts] = useState([]);
	const [debtRepayments, setDebtRepayments] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);

	function setRefreshColumns() {
		return [
			{
				accessorKey: "index",
				header: config.translate.index[lang],
				permissionModel: "index",
				size: 30,
			},
			{
				accessorKey: "name",
				permissionModel: "name",
				header: config.translate.name[lang],
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = row.name ? row.name : "...";
					return result;
				},
			},
			{
				accessorKey: "phone",
				permissionModel: "phone",
				enableColumnActions: false,
				header: config.translate.more[lang],
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = "";
					if (row.phone && row.phone?.length > 0) {
						result = (
							<div
								className="icon-comment-wrapper"
								style={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<a
									style={{ marginRight: "40px" }}
									href={`tel:${parsePhoneNumber(row.phone)}`}
								>
									<Image
										w="20"
										h="20"
										className="icon-comment"
										src="/assets/phone-call-svgrepo-com.svg"
										alt="phone"
										style={{
											filter: colorScheme === "dark" ? "invert(1)" : "",
										}}
									/>
								</a>
								<a style={{ marginRight: "40px" }} href={`mailto:${row.email}`}>
									<Image
										w="20"
										h="20"
										className="icon-comment"
										src="/assets/email-svgrepo-com.svg"
										alt="email"
										style={{
											filter: colorScheme === "dark" ? "invert(1)" : "",
										}}
									/>
								</a>
							</div>
						);
					}
					return result;
				},
			},
			// the summ of all debts and debt repayments of the client
			{
				accessorKey: "total",
				permissionModel: "debt",
				header: config.translate.debt[lang],
				// disable filters
				enableColumnFilters: true,
				size: 100,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					// loop through all debts and debt repayments of the client
					let total = 0;
					debts.forEach((debt) => {
						if (debt.client === row._id) {
							total += debt.amount;
						}
					});
					debtRepayments.forEach((debtRepayment) => {
						if (debtRepayment.client === row._id) {
							total -= debtRepayment.amount;
						}
					});
					return new Intl.NumberFormat("en-US").format(total);
				},
			},
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawDebts = await API.get(config.api.debt);
			if (rawDebts) setBedts(rawDebts);

			let rawDebtsRepayments = await API.get(config.api.debtRepayment);
			if (rawDebtsRepayments) setDebtRepayments(rawDebtsRepayments);

			setColumns(setRefreshColumns());

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
		// eslint-disable-next-line
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.client}
				query={{}}
				sorting={{ index: "asc" }}
				permissionModel={"client"}
				uploadType={null}
				limit={null}
				columns={columns}
				pageSize={100}
			/>
		);
}
