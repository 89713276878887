import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress from "nprogress";
import { useLang } from "../../helpers/language";
import moment from "moment";
import { useMantineColorScheme } from "@mantine/core";

export default function ProductEntryList() {
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();
	const [products, setProducts] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);

	function setRefreshColumns() {
		return [
			{
				accessorKey: "ID",
				permissionModel: "ID",
				header: "ID",
				size: 50,
				enableClickToCopy: true,
			},
			{
				accessorKey: "date",
				permissionModel: "date",
				header: config.translate.date[lang],
				enableClickToCopy: true,
				filterVariant: "date",
				size: 200,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					let result = moment(row.date).format("DD-MM-YYYY");
					return result;
				},
			},
			{
				accessorKey: "weight",
				permissionModel: "weight",
				header: config.translate.weight[lang],
				filterVariant: "range",
				filterFn: "between",
				size: 300,
			},
			{
				accessorFn: (row) => String(row.product?.ID),
				permissionModel: "product",
				header: config.translate.product[lang],
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: products.map((product) => ({
						value: product._id,
						label: String(product.ID),
					})),
				},
				size: 300,
				columnSizing: true,
				Cell: (
					RecievedObject,
					cell = RecievedObject.renderedCellValue,
					row = RecievedObject.row.original
				) => {
					return row?.product?.ID;
				},
			},
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawProducts = await API.get(config.api.product);
			if (rawProducts) setProducts(rawProducts);

			setColumns(setRefreshColumns());

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
		// eslint-disable-next-line
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.productEntry}
				query={{}}
				sorting={{ index: "asc" }}
				permissionModel={"product-entry"}
				uploadType={null}
				limit={null}
				customFiltersArrays={{
					products: products,
				}}
				populate={["product"]}
				columns={columns}
				pageSize={100}
			/>
		);
}
