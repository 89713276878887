import { Title, Text, Button, Container, Group } from "@mantine/core";
import classes from "./NotFoundTitle.module.css";
import config from "../config";
import { useLang } from "../helpers/language";

export default function ErrorPage() {
  const lang = useLang();
  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>
        {config.translate.errorpageTitle[lang]}
      </Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        {config.translate.errorpageText[lang]}
      </Text>
      <Group justify="center">
        <Button variant="subtle" size="md">
          {config.translate.errorpageButton[lang]}
        </Button>
      </Group>
    </Container>
  );
}
