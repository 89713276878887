import React, { useEffect, useState } from "react";
import config from "../../config";
import * as API from "../../helpers/api";
import NProgress, { set } from "nprogress";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import {
	Autocomplete,
	Card,
	Divider,
	Flex,
	Grid,
	Group,
	Paper,
	Text,
	CloseIcon,
	Button,
	Menu,
	Badge,
} from "@mantine/core";
import { AreaChart } from "@mantine/charts";
import { DatePicker } from "@mantine/dates";
import moment from "moment";
import Swal from "sweetalert2";
import {
	MantineReactTable,
	useMantineReactTable,
	MRT_ToggleFiltersButton,
} from "mantine-react-table";
import { Link } from "react-router-dom";

let formattedNumber = (number) =>
	new Intl.NumberFormat("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(number);

export default function ClientAnalyticsList() {
	const { lang } = useLang();
	const [clients, setClients] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [weight, setWeight] = useState(0);

	const [selectedClient, setSelectedClient] = useState(null);
	const [selectedClientDebsHistory, setSelectedClientDebsHistory] = useState([]);
	const [allDebts, setAllDebts] = useState([]);
	const [allDebtRepayments, setAllDebtRepayments] = useState([]);

	const [selectedClientDebs, setSelectedClientDebs] = useState([]);
	const [selectedClientDebtRepayments, setSelectedClientDebtRepayments] = useState([]);

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const columns = [
		{
			accessorKey: "type",
			permissionModel: "ID",
			header: config.translate.type[lang],
			size: 180,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return row.type === "debt" ? (
					<Badge color="red" variant="filled" size="lg">
						<Link
							to={`/debt/${row._id}`}
							style={{ color: "white", textDecoration: "none" }}
						>
							{config.translate.debt[lang]}
						</Link>
					</Badge>
				) : (
					<Badge color="green" variant="filled" size="lg">
						<Link
							to={`/debt-repayment/${row._id}`}
							style={{ color: "white", textDecoration: "none" }}
						>
							{config.translate.debtRepayment[lang]}
						</Link>
					</Badge>
				);
			},
		},
		{
			accessorKey: "date",
			permissionModel: "ID",
			header: config.translate.date[lang],
			size: 140,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return moment(row.date).format("DD/MM/YYYY");
			},
		},
		{
			accessorKey: "price",
			permissionModel: "ID",
			header: config.translate.price[lang],
			filterVariant: "range",
			filterFn: "between",
			size: 150,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return <Text fw={"bold"}>{formattedNumber(row.amount)}</Text>;
			},
		},
		{
			accessorKey: "totalDebtAtThatTime",
			permissionModel: "ID",
			header: config.translate.totalDebtAlThatTime[lang],
			size: 300,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				return (
					<Text c="red" fw={"bold"}>
						{formattedNumber(cell)}
					</Text>
				);
			},
		},
	];

	const table = useMantineReactTable({
		columns: columns ? columns : [],
		layoutMode: "grid-no-grow",
		columnResizeMode: "onChange",
		data: selectedClientDebsHistory ? selectedClientDebsHistory : [],
		columnVirtualizerProps: {
			overscan: 5, //adjust the number of columns that are rendered to the left and right of the visible area of the table
			estimateSize: () => 40, //if your columns are wider or , try tweaking this value to make scrollbar size more accurate
		},
		rowVirtualizerProps: {
			overscan: 10, //adjust the number or rows that are rendered above and below the visible area of the table
			estimateSize: () => 10, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
		},
		manualPagination: true,
		manualFiltering: true,
		manualSorting: false,
	});

	useEffect(() => {
		NProgress.start();
		(async () => {
			// get all clients
			let rawClients = await API.get(config.api.client);
			if (rawClients) setClients(rawClients);
			setBusy(false);
		})();
		NProgress.done();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		// selectedClient relaterd data of orders when this client is selected
		NProgress.start();
		(async () => {
			if (!selectedClient) return;
			// get all debts for the selected client within the date range
			let rawDebts = await API.get(config.api.debt, {
				client: selectedClient._id,
				date: {
					$gte: startDate.toISOString(),
					$lte: endDate.toISOString(),
				},
			});
			if (rawDebts) setAllDebts(rawDebts);

			// get all debt repayments for the selected client within the date range
			let rawDebtRepayment = await API.get(config.api.debtRepayment, {
				client: selectedClient._id,
				date: {
					$gte: startDate.toISOString(),
					$lte: endDate.toISOString(),
				},
			});
			if (rawDebtRepayment) setAllDebtRepayments(rawDebtRepayment);

			// count total debt of the selected client
			let clientDebt = rawDebts.reduce((acc, debt) => {
				return acc + debt.amount;
			}, 0);
			setSelectedClientDebs(clientDebt);

			let clientDebtRepayment = rawDebtRepayment.reduce((acc, debtRepayment) => {
				return acc + debtRepayment.amount;
			}, 0);
			setSelectedClientDebtRepayments(clientDebtRepayment);

			selectedClient.debt = clientDebt - clientDebtRepayment;

			// add all items in date order to client debt and debt-repayment history
			let totalDebtAtThatTime = 0;

			// combine all debts and debt-repayments into one array and sort by date,  and add type attribute on every object to distinguish between debt and debt-repayment
			let allDebtsAndRepayments = rawDebts
				.map((debt) => {
					return {
						...debt,
						type: "debt",
					};
				})
				.concat(
					rawDebtRepayment.map((debtRepayment) => {
						return {
							...debtRepayment,
							type: "debtRepayment",
						};
					})
				);

			// sort by date
			allDebtsAndRepayments.sort((a, b) => {
				return new Date(a.date) - new Date(b.date);
			});

			// sum all debts and debt-repayments to get total debt at that time
			allDebtsAndRepayments.forEach((debtOrRepayment) => {
				if (debtOrRepayment.type === "debt") {
					totalDebtAtThatTime += debtOrRepayment.amount;
				} else {
					totalDebtAtThatTime -= debtOrRepayment.amount;
				}
				debtOrRepayment.totalDebtAtThatTime = totalDebtAtThatTime;
			});

			selectedClient.debtHistory = allDebtsAndRepayments;

			// reorder by date
			selectedClient.debtHistory.sort((a, b) => {
				return new Date(a.date) - new Date(b.date);
			});

			setSelectedClientDebsHistory(selectedClient.debtHistory);
		})();
		NProgress.done();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient, startDate, endDate]);

	if (!isBusy) {
		if (startDate && endDate) {
			if (startDate > endDate) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Start date can't be greater than end date",
				});
				setStartDate(endDate);
			}

			return (
				<>
					<Paper withBorder p="md" radius="md" shadow="xs">
						<Grid gutter="md" columns={12} justify="center" align="center">
							<Grid.Col span={3}>
								<Group justify="center">
									<Text size="xl" weight={700} w={"100%"} align="center">
										{config.translate.startDate[lang]}
									</Text>
									<DatePicker
										value={startDate}
										onChange={(date) => {
											//  date is GMT=4 change it to MGMT=0
											date.setHours(0);
											setStartDate(date);
										}}
										valueFormat="DD/MM/YYYY"
										firstDayOfWeek={6}
									/>
								</Group>
							</Grid.Col>
							<Grid.Col span={6}>
								<Text size="xl" weight={700} align="center" w={"100%"}>
									{config.translate.client[lang]}
								</Text>
								<Autocomplete
									label={config.translate.selectClient[lang]}
									placeholder={config.translate.selectClient[lang]}
									mb={40}
									data={clients.map((client) => ({
										value: client._id,
										label: client.name,
									}))}
									value={selectedClient?.name || ""}
									onSearchChange={(searchValue) => {
										// This handles input changes for searching
										setSelectedClient({ name: searchValue });
									}}
									onChange={(clientLabel) => {
										// This only triggers when an option is selected
										const client = clients.find(
											(client) => client.name === clientLabel
										);
										setSelectedClient(
											client || (clientLabel && { name: clientLabel })
										);
									}}
								/>
								{selectedClient && (
									<Flex
										justify={"center"}
										direction={"column"}
										align="center"
										w={"100%"}
										gap={20}
									>
										<Flex align="center" justify={"center"} w={"100%"} gap={20}>
											<Card>
												<Text>{config.translate.name[lang]}</Text>
												<Text c={"blue.6"}>{selectedClient?.name}</Text>
											</Card>
											<Card>
												<Text>{config.translate.phone[lang]}</Text>
												<Text c={"blue.6"}>{selectedClient?.phone}</Text>
											</Card>
											<Card>
												<Text>{config.translate.totalDebt[lang]}</Text>
												<Text c={"red.6"} fw={"bold"}>
													{formattedNumber(selectedClientDebs)}
												</Text>
											</Card>
											<Card>
												<Text>
													{config.translate.totalDebtRepayment[lang]}
												</Text>
												<Text c={"green.6"} fw={"bold"}>
													{formattedNumber(selectedClientDebtRepayments)}
												</Text>
											</Card>
										</Flex>
									</Flex>
								)}
							</Grid.Col>
							<Grid.Col span={3}>
								<Group justify="center">
									<Text size="xl" weight={700} w={"100%"} align="center">
										{config.translate.endDate[lang]}
									</Text>
									<DatePicker
										value={endDate}
										onChange={(date) => {
											let dateEnd = new Date(date);
											dateEnd.setHours(0);
											setEndDate(dateEnd);
										}}
										valueFormat="DD/MM/YYYY"
										firstDayOfWeek={6}
									/>
								</Group>
							</Grid.Col>
							<Divider my="lg" />
							<Grid.Col span={12}>
								<Text size="xl" weight={700} align="center" mb={20} w={"100%"}>
									{config.translate.details[lang]} -{" "}
									{config.translate.debtList[lang]}
								</Text>
								<MantineReactTable table={table} />
							</Grid.Col>
						</Grid>
					</Paper>
				</>
			);
		}
	}
}
