import React, { useMemo } from "react";
import { ListPage } from "../ListPage";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import config from "../../config";
import moment from "moment";
import { Image, useMantineColorScheme } from "@mantine/core";

const parsePhoneNumber = (number) => {
	return `+374${number.split(/[-*\/\s]+/).join("")}`;
};

function calculateAge(birthdate) {
	const today = moment();
	const birthdateMoment = moment(birthdate, moment.ISO_8601, true);
	if (!birthdateMoment.isValid()) {
		return "Неверная дата";
	}
	const years = today.diff(birthdateMoment, "years");
	birthdateMoment.add(years, "years");
	let formattedResult = years;
	return formattedResult;
}

export default function UserList() {
	const { user } = useUser();
	const { colorScheme } = useMantineColorScheme();
	const { lang } = useLang();
	const columns = [
		{
			accessorKey: "index",
			header: config.translate.index[lang],
			permissionModel: "index",
			size: 30,
		},
		{
			accessorKey: "name",
			permissionModel: "name",
			header: config.translate.name[lang],
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = row.name ? row.name : "...";
				return result;
			},
		},
		{
			accessorKey: "phone",
			permissionModel: "phone",
			enableColumnActions: false,
			header: config.translate.more[lang],
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = "";
				if (row.phone && row.phone?.length > 0) {
					result = (
						<div
							className="icon-comment-wrapper"
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<a
								style={{ marginRight: "40px" }}
								href={`tel:${parsePhoneNumber(row.phone)}`}
							>
								<Image
									w="20"
									h="20"
									className="icon-comment"
									src="/assets/phone-call-svgrepo-com.svg"
									alt="phone"
									style={{
										filter: colorScheme === "dark" ? "invert(1)" : "",
									}}
								/>
							</a>
							<a style={{ marginRight: "40px" }} href={`mailto:${row.email}`}>
								<Image
									w="20"
									h="20"
									className="icon-comment"
									src="/assets/email-svgrepo-com.svg"
									alt="email"
									style={{
										filter: colorScheme === "dark" ? "invert(1)" : "",
									}}
								/>
							</a>
						</div>
					);
				}
				return result;
			},
		},
		{
			accessorKey: "dateOfBirth",
			permissionModel: "dateOfBirth",
			header: config.translate.dateOfBirth[lang],
			enableClickToCopy: true,
			filterVariant: "date",
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = moment(row.dateOfBirth).format("MM.DD.YYYY");
				return result;
			},
		},
		{
			accessorKey: "age",
			permissionModel: "dateOfBirth",
			header: config.translate.age[lang],
			filterVariant: "range",
			filterFn: "between",
			size: 200,
			Cell: (
				RecievedObject,
				cell = RecievedObject.renderedCellValue,
				row = RecievedObject.row.original
			) => {
				let result = calculateAge(row.dateOfBirth);
				return result;
			},
		},
	];
	return (
		<ListPage
			route={config.api.user}
			query={{}}
			sorting={{ index: "asc" }}
			permissionModel={"user"}
			uploadType={null}
			limit={null}
			// populate={[""]}
			columns={columns}
			pageSize={100}
		/>
	);
}
