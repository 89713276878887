import React, { useState, useEffect, useRef } from "react";
import * as API from "../helpers/api";
import config from "../config";
import Loading from "./modules/loading";
import { useUser } from "../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../helpers/language";
import NProgress, { set } from "nprogress";
import { IconCalendar, IconPlus, IconTrash } from "@tabler/icons-react";
import { useParams, useHistory } from "react-router-dom";
import { UPDATE } from "../helpers/CRUD";
import { useReactToPrint } from "react-to-print";
import { DateInput, DateInputProps } from "@mantine/dates";

import {
	Autocomplete,
	Badge,
	Box,
	Button,
	Card,
	Center,
	Checkbox,
	Container,
	Divider,
	Flex,
	Grid,
	Group,
	Image,
	Input,
	NumberInput,
	Paper,
	Select,
	Stepper,
	Table,
	Text,
	Title,
} from "@mantine/core";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function Home() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.productEntry;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	const [allProducts, setAllProducts] = useState([]);
	const [allDebts, setAllDebts] = useState([]);
	const [allDebtRepayments, setAllDebtRepayments] = useState([]);
	const [allEncashments, setAllEncashments] = useState([]);
	const [allOrders, setAllOrders] = useState([]);
	const [allClients, setAllClients] = useState([]);
	const [allProductEntries, setAllProductEntries] = useState([]);

	useEffect(() => {
		(async () => {
			NProgress.start();

			// all products
			let products = await API.get(config.api.product, {});
			setAllProducts(products);

			let debts = await API.get(config.api.debt, {});
			setAllDebts(debts);

			let debtRepayments = await API.get(config.api.debtRepayment, {});
			setAllDebtRepayments(debtRepayments);

			let encashments = await API.get(config.api.encashment, {});
			setAllEncashments(encashments);

			let orders = await API.get(config.api.order, {});
			setAllOrders(orders);

			let clients = await API.get(config.api.client, {});
			setAllClients(clients);

			let productEntries = await API.get(config.api.productEntry, {});
			setAllProductEntries(productEntries);

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "product") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<Container size="xl" padding="md">
				<Grid gutter="md">
					<Grid.Col span={12} mb={40}>
						<Flex
							direction="column"
							align="center"
							justify="center"
							style={{
								height: "100%",
							}}
						>
							<table
								className="home-table"
								style={{
									width: "100%",
									textAlign: "center",
									borderRadius: "5px",
								}}
							>
								<tbody>
									<tr>
										<td className="home-table-td_first">
											<img src="/logo.svg" alt="dms" />
										</td>
									</tr>

									<tr>
										<td className="home-table-td_second">
											Коммуникационная платформа для компаний, где полная
											<br /> конфиденциальность имеет решающее значение для
											бизнеса.
										</td>
									</tr>

									<tr>
										<td className="home-table-td_third">
											<a
												href="https://admin.fluorescence.pro"
												style={{
													fontSize: "20px",
													textDecoration: "none",
												}}
												type="button"
											>
												Перейти в основную платформу
											</a>
										</td>
									</tr>

									<tr>
										<td className="home-table-td_fourth">
											Если у Вас возникли вопросы обращайтесь по адресу <br />
											<a
												href="mailto:info@deepmindsystems.com"
												style={{
													fontSize: "20px",
													textDecoration: "none",
												}}
											>
												info@deepmindsystems.com
											</a>
										</td>
									</tr>

									<tr>
										<td className="home-table-td_fifth">
											С уважением, команда{" "}
											<a
												href="https://deepmindsystems.com"
												style={{
													fontSize: "20px",
													textDecoration: "none",
												}}
											>
												Deepminsystems
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</Flex>
					</Grid.Col>

					<Grid.Col span={4}>
						<Link
							to={`/product`}
							style={{
								fontSize: "30px",
								textDecoration: "none",
							}}
						>
							<Card shadow="sm" padding="lg" radius="md" withBorder>
								<Title order={1} align="center" mb="md">
									{
                    config.translate.products[lang]
                  }
								</Title>

								<Text
									align="center"
									style={{
										fontSize: "30px",
									}}
									fw={900}
									mb="md"
									c={"cyan.6"}
								>
									{allProducts.length}
								</Text>
							</Card>
						</Link>
					</Grid.Col>

					<Grid.Col span={4}>
						<Link
							to={`/product-entry`}
							style={{
								fontSize: "30px",
								textDecoration: "none",
							}}
						>
							<Card shadow="sm" padding="lg" radius="md" withBorder>
								<Title order={1} align="center" mb="md">
									{config.translate.productEntries[lang]}
								</Title>

								<Text
									align="center"
									style={{
										fontSize: "30px",
									}}
									fw={900}
									mb="md"
									c={"cyan.6"}
								>
									{allProductEntries.length}
								</Text>
							</Card>
						</Link>
					</Grid.Col>

					<Grid.Col span={4}>
						<Link
							to={`/client`}
							style={{
								fontSize: "30px",
								textDecoration: "none",
							}}
						>
							<Card shadow="sm" padding="lg" radius="md" withBorder>
								<Title order={1} align="center" mb="md">
									{config.translate.clients[lang]}  
								</Title>

								<Text
									align="center"
									style={{
										fontSize: "30px",
									}}
									fw={900}
									mb="md"
									c={"cyan.6"}
								>
									{allClients.length}
								</Text>
							</Card>
						</Link>
					</Grid.Col>

					<Grid.Col span={4}>
						<Link
							to={`/order`}
							style={{
								fontSize: "30px",
								textDecoration: "none",
							}}
						>
							<Card shadow="sm" padding="lg" radius="md" withBorder>
								<Title order={1} align="center" mb="md">
									{config.translate.orders[lang]}
								</Title>

								<Text
									align="center"
									style={{
										fontSize: "30px",
									}}
									fw={900}
									mb="md"
									c={"cyan.6"}
								>
									{allOrders.length}
								</Text>
							</Card>
						</Link>
					</Grid.Col>

					<Grid.Col span={4}>
						<Link
							to={`/debt`}
							style={{
								fontSize: "30px",
								textDecoration: "none",
							}}
						>
							<Card shadow="sm" padding="lg" radius="md" withBorder>
								<Title order={1} align="center" mb="md">
									{
                    config.translate.debts[lang]
                  }
								</Title>

								<Text
									align="center"
									style={{
										fontSize: "30px",
									}}
									fw={900}
									mb="md"
									c={"cyan.6"}
								>
									{allDebts.length}
								</Text>
							</Card>
						</Link>
					</Grid.Col>

					<Grid.Col span={4}>
						<Link
							to={`/debt-repayment`}
							style={{
								fontSize: "30px",
								textDecoration: "none",
							}}
						>
							<Card shadow="sm" padding="lg" radius="md" withBorder>
								<Title order={1} align="center" mb="md">
									{
                    config.translate.debtRepayments[lang]
                  }
								</Title>

								<Text
									align="center"
									style={{
										fontSize: "30px",
									}}
									fw={900}
									mb="md"
									c={"cyan.6"}
								>
									{allDebtRepayments.length}
								</Text>
							</Card>
						</Link>
					</Grid.Col>
				</Grid>
			</Container>
		);
}
